import React, { useState } from "react";
import "./style.css";

import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Menu from "../../assets/icons/Menu";
import Close from "../../assets/icons/Close";
import Logo from "../Logo";
import Container from "../../layouts/Container";
import { ACADEMICS_ITEMS, SCHOOL, STANDARD_ITEMS } from "../../utils/constants";
import { Button, Dropdown, Space } from "antd";
import AngleDown from "../../assets/icons/AngleDown";

const Navbar = ({
  items = [],
  logo = <></>,
  standards = [],
  academics = [],
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = (path) => {
    return location.pathname === path;
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenuOnMobile = () => {
    if (window.innerWidth <= 1150) {
      setShowMenu(false);
    }
  };

  return (
    <header className="nav-header header">
      <Container>
        <nav className="nav container pt-2">
          <div className="flex items-center gap-2 lg:w-2/3 sm:w-1/2 w-full logo-div">
            <div className="lg:w-1/12 sm:w-1/4 w-1/5 lg:mb-2 mb-0">
              <Logo url="/" />
            </div>
            <div className="lg:block hidden">
              <Link to={"/"} className={"w-full flex flex-col"}>
                <span className="text-blue-600 font-semibold xl:text-xl lg:text-base capitalize">
                  {SCHOOL.FULLNAME}
                </span>
                <span className="w-full text-center text-xs text-blue-800 font-medium">
                  Only Gujarati Medium (6 to 10)
                </span>
              </Link>
            </div>
          </div>
          <div
            className={`nav__menu ${showMenu ? "show-menu" : ""}`}
            id="nav-menu"
          >
            <ul className="nav__list">
              {items.map((item, index) => {
                return (
                  <li
                    className={`nav__item ${
                      isActive(item.path) ? "active__nav__item" : ""
                    }`}
                    key={index}
                  >
                    <NavLink
                      to={item.path}
                      className="nav__link font-medium"
                      onClick={closeMenuOnMobile}
                    >
                      {item.label}
                    </NavLink>
                  </li>
                );
              })}

              <Dropdown
                disabled={standards.length === 0}
                className="cursor-pointer"
                menu={{
                  items: STANDARD_ITEMS(standards, toggleMenu),
                }}
                trigger={["click"]}
              >
                <Link onClick={(e) => e.preventDefault()} className="nav__item">
                  <Space
                    className={`${
                      standards.length === 0 ? "text-blue-400" : "text-blue-700"
                    }`}
                  >
                    Standards
                    <AngleDown />
                  </Space>
                </Link>
              </Dropdown>

              <Button
                className="bg-blue-500 text-white sm:mr-auto sm:w-fit w-full"
                size="large"
                target="_blank"
                onClick={() => {
                  navigate("/contact");
                  toggleMenu();
                }}
              >
                Contact Us
              </Button>
            </ul>
            <div className="nav__close" id="nav-close" onClick={toggleMenu}>
              <Close />
            </div>
          </div>

          <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
            <Menu />
          </div>
        </nav>
      </Container>
    </header>
  );
};

export default Navbar;
