import React from "react";
import Heading from "../../layouts/Heading";

const LocationSection = () => {
  return (
    <section>
      <div className="my-2">
        <Heading className="text-blue-600 capitalize mt-10">
          Our Location
        </Heading>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3307.739820319033!2d-118.2643469!3d34.0396612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c77ffc1f6549%3A0x99d8fa0295cb8f8b!2sTech%20Holding!5e0!3m2!1sen!2sus!4v1674827651385!5m2!1sen!2sin"
        className="w-full h-96 border border-gray-100 p-1"
        title="vvg-schoole-location"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </section>
  );
};

export default LocationSection;
