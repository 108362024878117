import React from "react";

const ExpertiesCard = ({
  className = "",
  imageUrl,
  title = "",
  description = "",
}) => {
  return (
    <div
      className={
        "card-shadow p-6 bg-white shadow-xl justify-self-stretch rounded-lg bg-dark-blue-gradiant " +
        className
      }
    >
      {imageUrl && (
        <div className="mb-2">
          <img
            src={imageUrl}
            width={50}
            height={60}
            alt={title}
            className="text-white bg-white h-14 w-14 rounded-full p-1"
          />
        </div>
      )}

      <h5 className="mb-2 lg:text-2xl text-xl font-semibold tracking-tight text-white">
        {title}
      </h5>
      <p className="mb-3 font-normal lg:text-base text-sm text-gray-100">
        {description}
      </p>
    </div>
  );
};

export default ExpertiesCard;
