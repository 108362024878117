import std_6_7_computer from "../assets/pdfs/subject-info/subject-info-6-7/std-6-7-computer.pdf";
import std_6_7_english from "../assets/pdfs/subject-info/subject-info-6-7/std-6-7-english.pdf";
import std_6_7_fundamental_maths from "../assets/pdfs/subject-info/subject-info-6-7/std-6-7-fundamental-maths.pdf";
import std_6_7_general_science from "../assets/pdfs/subject-info/subject-info-6-7/std-6-7-general-science.pdf";
import std_6_7_gk from "../assets/pdfs/subject-info/subject-info-6-7/std-6-7-GK.pdf";
import std_6_7_gujarati from "../assets/pdfs/subject-info/subject-info-6-7/std-6-7-gujarati.pdf";
import std_6_7_maths from "../assets/pdfs/subject-info/subject-info-6-7/std-6-7-maths.pdf";
import std_6_7_sanskrit from "../assets/pdfs/subject-info/subject-info-6-7/std-6-7-sanskrit.pdf";
import std_6_7_science from "../assets/pdfs/subject-info/subject-info-6-7/std-6-7-science.pdf";
import std_6_7_ss from "../assets/pdfs/subject-info/subject-info-6-7/std-6-7-SS.pdf";

import std_6_maths_test from "../assets/pdfs/test-series/test-6/std-6-maths.pdf";
import std_7_maths_test from "../assets/pdfs/test-series/test-7/std-7-maths.pdf";

import march_2022 from "../assets/pdfs/results/2022-march.pdf";

// order of subjects : Maths, Science, Eng., Social Science, Gujarti, Sanskrit, GK, computer, Extra subjet (funa maths and genral science)

export const std_6_7_subject_info = {
  std_6_7_computer,
  std_6_7_english,
  std_6_7_fundamental_maths,
  std_6_7_general_science,
  std_6_7_gk,
  std_6_7_gujarati,
  std_6_7_maths,
  std_6_7_sanskrit,
  std_6_7_science,
  std_6_7_ss,
};

export const std_6_test = {
  std_6_maths_test
};

export const std_7_test = {
  std_7_maths_test
};

export const std_6_7_subjects = [
  "maths",
  "science",
  "english",
  "social_science",
  "gujarati",
  "sanskrit",
  "gk",
  "computer",
  "fundamental_maths",
  "general_science",
]

export const results = [
  march_2022
]
