export const about_us_content = `વિદ્યાયન વિકાસ ગ્રુપની વિશેષતા, ## પ્રિય વાલી મિત્રો, ## ધો. 8, 9,10 માં
    મુખ્ય ચાર વિષય ગણિત, વિજ્ઞાન, અંગ્રેજી અને સામાજિક વિજ્ઞાન માં દરરોજ ચાર
    કલાક નું શિક્ષસ કાર્ય ## અમારી આ મુખ્ય વિશેષજતાને સરણે વિદ્યાર્થીને કરેક
    વિષયના તજજ્ઞ શિક્ષકો સાથે એક ક્લાકનો વિષય દીઠ સમય મળે છે. ## શું આ કોઈ ટપુશન
    કલાસ / અન્ય સંસ્થામાં આ પ્રકારની પ્રવૃત્તિ થાય છે ?## સોમવાર થી શુક્રવાર
    સુધી ગણિત અને વિજ્ઞાન મુખ્ય વિષયોમાં અભ્યાસ કરાયા ભાદ શનિવારે ગણિત અને
    વિજ્ઞાનમાં પાંચ દિવસનો રિવિઝન ટેસ્ટ ## ધોરણ. 8, 9, 10 ગણિતના પ્રેકટિસ વર્ક
    ના તમામ દાખલાઓનો વર્ગખંડમાં સંપૂર્ણ અભ્યાસ ## સોમવારે અંગ્રેજી અને સામાજિક
    વિજ્ઞાન વિષયનો છ દિવસનો રિવિઝન ટેસ્ટ ## ચૅપ્ટર પૂર્ણ થયા બાદ ચૅપ્ટરવાર ટેસ્ટ
    ## ગુજરાતી અને સંસ્કૃતમાં પણ ખૂબ જ ઓછી મહેનતે વિદ્યાર્થી સારા માકર્સ મેળવે એ
    પ્રકારે આયોજન ## વિષયદીઠ એક કલાક મળતો હોવાથી યોગ્ય સમયે આટલા ટેસ્ટ અને
    રિવિઝન બાદ પણ યોગ્ય સમયે શિક્ષણ કાર્ય પૂર્ણ ## વિદ્યાર્થીઓને અન્ય ટયુશન માટે
    જવાની જરૂર પડતી નથી. ## ઘણી સંસ્થાઓમાં તજજ્ઞ શિક્ષકોના અભાવે ટટ્યુશન રાખવાની
    જરૂર પડતી હોય છે. ## સૌથી મહત્ત્વનું ટેસ્ટનું આયોજન કરી શકાય છે. ##
    વાંચવાનો સમય મળી શકે છે. ## તો વિદ્યાર્થી ટેસ્ટમાં યોગ્ય વિષયને યોગ્ય ન્યાય
    આપી શકે છે. ## શાળાનું હોમવર્ક/ટયુશનનું હોમર્વક ## એક ટયુશનમાં ટેસ્ટ તો બીજા
    ટયુશનમાં એજ સમયે રાખેલ ટેસ્ટ તો ભાળક કેવી રીતે ન્યાય આપી શકે? ## માટે
    શ્રેષ્ઠ પસંદગી એટલે જ વિદ્યાયન વિકાસ ગૃપ ## ગણિત અને વિજ્ઞાન વિષય માટે
    એમ.ડી. પટેલ સરના વિડીયો પણ ઉપલબ્ધ હોય છે. માટે શાળાની સાથે ઘરે પણ વિદ્યાર્થી
    રિવિઝન કરી શકે`;

export const home_content = `પંચમહાલ જિલ્લામાં પ્રથમવાર તમામ ## વિષયના વર્ષોના અનુભવી અને સફળ શિક્ષકો એક જ સ્થળે`;

export const TEST_DATA = {
  "STD.6": {
    ENGLISH: [
      {
        _id: 1,
        marks: 50,
        testDate: "NA",
        chapter: "Unit - 1 Exercise and Grammar",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        marks: 59,
        testDate: "NA",
        chapter: "Unit - 2 Exercise and Grammar ",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        marks: 50,
        testDate: "28/10/2023",
        chapter: "Unit - 3 Exercise and Grammar ",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        marks: 50,
        testDate: "29/10/2023",
        chapter: "Unit - 4 Exercise and Grammar ",
        login: { uuid: 4 },
      },
    ],
    "SOCIAL SCIENCE": [
      {
        _id: 1,
        marks: 55,
        testDate: "12/08/23",
        chapter: "Ch. 1 ચાલો, ઈતિહાસ જાણીએ ભાગ – બી",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        marks: 60,
        testDate: "24/07/23",
        chapter: "Ch. 2 આદિમાનવથી સ્થાયી જીવનની સફર ભાગ – બી",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        marks: 75,
        testDate: "NA",
        chapter: "Ch. 3 પ્રાચીન નગરો અને ગ્રંથો ભાગ — બી",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        marks: 60,
        testDate: "NA",
        chapter: "Ch. 4 ભારતની પ્રારંભિક રાજ્ય વ્યવસ્થા ભાગ – બી",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        marks: 65,
        testDate: "NA",
        chapter: "Ch. 9 આપણું ઘર પૃથ્વ ભાગ – બી",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        marks: 75,
        testDate: "NA",
        chapter: "Ch. 10 પૃથ્વીના આવરણો ભાગ – બી",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        marks: 70,
        testDate: "NA",
        chapter: "14 વિવિધતામાં એકતા ભાગ – બી",
        login: { uuid: 7 },
      },
      {
        _id: 8,
        marks: 80,
        testDate: "NA",
        chapter: "15 સરકાર",
        login: { uuid: 8 },
      },
    ],
    GUJARATI: [
      {
        _id: 1,
        marks: 30,
        testDate: "NA",
        chapter: "1 અમે તો આવું જ કરવાના",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        marks: 40,
        testDate: "NA",
        chapter: "2 સૌંદર્યની સરવાણી",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        marks: 35,
        testDate: "NA",
        chapter: "3 એકની ફલાઈટ બીજાની ફાઈટ",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        marks: 20,
        testDate: "NA",
        chapter: "4 અંતરિક્ષમાં સંતાકૂકડી",
        login: { uuid: 4 },
      },
    ],
    MATHS: [
      {
        _id: 1,
        marks: 25,
        testDate: "15/09/2024",
        chapter: "1 Fundamental Maths",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        marks: 20,
        testDate: "30/07/2023",
        chapter: "2 Fundamental Maths Surprise Test",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        marks: 40,
        testDate: "30/07/2023",
        chapter: "Surprise Test",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        marks: 20,
        testDate: "NA",
        chapter: "Ch. 2 Part - A",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        marks: 20,
        testDate: "NA",
        chapter: "Ch. 2 Part - B",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        marks: 20,
        testDate: "NA",
        chapter: "Ch. 3 Part - A",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        marks: 25,
        testDate: "NA",
        chapter: "Ch. 3 Part - B",
        login: { uuid: 7 },
      },
      {
        _id: 8,
        marks: 30,
        testDate: "NA",
        chapter: "Ch.1,2,3 Part - A",
        login: { uuid: 8 },
      },
      {
        _id: 9,
        marks: 30,
        testDate: "25/10/2023",
        chapter: "Ch.1,2,3 Part - B",
        login: { uuid: 9 },
      },
      {
        _id: 10,
        marks: 20,
        testDate: "NA",
        chapter: "Ch.4,5 Part - A",
        login: { uuid: 10 },
      },
      {
        _id: 11,
        marks: 25,
        testDate: "26/10/2023",
        chapter: "Ch.4,5 Part - B",
        login: { uuid: 11 },
      },
      {
        _id: 12,
        marks: 20,
        testDate: "NA",
        chapter: "Ch. 6 Part - A",
        login: { uuid: 12 },
      },
      {
        _id: 13,
        marks: 20,
        testDate: "NA",
        chapter: "Ch. 6 Part - B",
        login: { uuid: 13 },
      },
    ],
    SCIENCE: [
      {
        _id: 1,
        marks: 50,
        testDate: "25/07/2023",
        chapter: "Ch. 1 આહારના ઘટકો ભાગ – ૧",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        marks: 20,
        testDate: "NA",
        chapter: "Part - B ",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        marks: 50,
        testDate: "10/08/2023",
        chapter: "CH. 2 વસ્તુઓના જૂથ બનાવવા ભાગ – ૧",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        marks: 20,
        testDate: "NA",
        chapter: "Part -2",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        marks: 50,
        testDate: "NA",
        chapter: "Ch. 3 ઉષ્મા ભાગ – ૧",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        marks: 30,
        testDate: "NA",
        chapter: "Part -2",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        marks: 50,
        testDate: "09/09/23",
        chapter: "Ch. 7 વનસ્પતિની જાણકારી મેળવવી",
        login: { uuid: 7 },
      },
      {
        _id: 8,
        marks: 20,
        testDate: "25/08/23",
        chapter: "Part -2",
        login: { uuid: 8 },
      },
    ],
    SANSKRIT: [
      {
        _id: 1,
        marks: 40,
        testDate: "NA",
        chapter: "1. वन्दना",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        marks: 40,
        testDate: "NA",
        chapter: "2. चित्रपदानि १.१४",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        marks: 40,
        testDate: "NA",
        chapter: "3. आकाशः पतति",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        marks: 20,
        testDate: "NA",
        chapter: "4. सङ्ख्या",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        marks: 20,
        testDate: "NA",
        chapter: "5. हस्ती हस्ती हस्ती",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        marks: 40,
        testDate: "NA",
        chapter: "6. सप्त वासरा",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        marks: 40,
        testDate: "NA",
        chapter: "7. करोति",
        login: { uuid: 7 },
      },
      {
        _id: 8,
        marks: 40,
        testDate: "NA",
        chapter: "8. काकस्य चार्तुयम्",
        login: { uuid: 8 },
      },
      {
        _id: 9,
        marks: 40,
        testDate: "NA",
        chapter: "9. समयः",
        login: { uuid: 9 },
      },
      {
        _id: 10,
        marks: 76,
        testDate: "25/10/2023",
        chapter: "10. Chapter 1 to 9",
        login: { uuid: 10 },
      },
      {
        _id: 11,
        marks: 30,
        testDate: "NA",
        chapter: "11. Surprise Test",
        login: { uuid: 11 },
      },
      {
        _id: 12,
        marks: 80,
        testDate: "NA",
        chapter: "12. Unit 1 to 9",
        login: { uuid: 12 },
      },
      {
        _id: 13,
        marks: 80,
        testDate: "NA",
        chapter: "13. ૧ થી ૯ વ્યાકરણ",
        login: { uuid: 13 },
      },
    ],
  },
  "STD.7": {
    ENGLISH: [
      {
        _id: 1,
        marks: 80,
        testDate: "05/10/23",
        chapter: "Practice Test - 2 ",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        marks: 25,
        testDate: "NA",
        chapter: "Unit - 2 Unit - 1 and Grammar",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        marks: 50,
        testDate: "NA",
        chapter: "Unit - 3 Unit - 2 and Grammar",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        marks: 50,
        testDate: "30/10/23",
        chapter: "Unit - 4 Unit - 3 and Grammar",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        marks: 50,
        testDate: "30/10/23",
        chapter: "Unit - 5 Unit - 4 and Grammar",
        login: { uuid: 5 },
      },
    ],
    MATHS: [
      {
        _id: 1,
        marks: 20,
        testDate: "30/07/23",
        chapter: "Surprise Test",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        marks: 20,
        testDate: "15/09/23",
        chapter: "Fundamental Maths",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        marks: 40,
        testDate: "21/07/23",
        chapter: "Maths Ch. - 1 ",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        marks: 40,
        testDate: "17/10/23",
        chapter: "Chapter - 1 ",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        marks: 50,
        testDate: "19/10/23",
        chapter: "Chapter - 2, 3",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        marks: 30,
        testDate: "25/10/23",
        chapter: "Chapter - 4, 5 Part - A",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        marks: 30,
        testDate: "25/10/23",
        chapter: "Chapter - 4, 5 Part - B",
        login: { uuid: 7 },
      },
      {
        _id: 8,
        marks: 20,
        testDate: "26/10/23",
        chapter: "Chapter - 6 ",
        login: { uuid: 8 },
      },
      {
        _id: 9,
        marks: 25,
        testDate: "NA",
        chapter: "Chapter - 7 ",
        login: { uuid: 9 },
      },
    ],
    GUJARATI: [
      {
        _id: 1,
        marks: 30,
        testDate: "NA",
        chapter: "1 મેળામાં 2 આજની ઘડી તે રળિયામણી",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        marks: 30,
        testDate: "NA",
        chapter: "3 પરીક્ષા 4 બે ખાનાનો પરિગ્રહ",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        marks: 30,
        testDate: "NA",
        chapter: "5 રાનમાં 6 ભીખુ",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        marks: 30,
        testDate: "NA",
        chapter: "7 જીવન પાથેય 8 માલમ હલેસાં માર",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        marks: 30,
        testDate: "NA",
        chapter: "9 બાનો વાડો 10 વલયની અવકાશી સફર",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        marks: 40,
        testDate: "18/10/23",
        chapter: "Chapter - 1 to 5",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        marks: 40,
        testDate: "18/10/23",
        chapter: "Chapter - 6 to 10",
        login: { uuid: 7 },
      },
      {
        _id: 8,
        marks: 30,
        testDate: "01/08/23",
        chapter: "Surprice Test",
        login: { uuid: 8 },
      },
      {
        _id: 9,
        marks: 155,
        testDate: "NA",
        chapter: "Chapter- 1 to 10 Grammar",
        login: { uuid: 9 },
      },
    ],
    "SOCIAL SCIENCE": [
      {
        _id: 1,
        marks: 65,
        testDate: "25/07/23",
        chapter: "Ch. 1 રાજપૂત યુગ : નવા શાસકો અને રાજ્યો પાર્ટ - એ + પાર્ટ-બી",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        marks: 65,
        testDate: "25/07/23",
        chapter: "Ch. 2 દિલ્લી સલ્તનત પાર્ટ - એ + પાર્ટ-બી",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        marks: 60,
        testDate: "NA",
        chapter: "Ch. 3 મુઘલ સામ્રાજ્ય પાર્ટ-એ + પાર્ટ-બી",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        marks: 75,
        testDate: "NA",
        chapter:
          "Ch. 4 મધ્યયુગીન સ્થાપત્યો, શહેરો, વેપારી અને કારીગરો પાર્ટ-એ + પાર્ટ - બી",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        marks: 75,
        testDate: "NA",
        chapter:
          "Ch. 10 પૃથ્વીની આંતરિક રચના અને ભૂમિસ્વરૂપો પાર્ટ-એ + પાર્ટ-બી",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        marks: 75,
        testDate: "NA",
        chapter: "Ch. 12 વાતાવરણની સજીવો પર અસરો પાર્ટ - એ + પાર્ટ-બી",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        marks: 105,
        testDate: "NA",
        chapter:
          "Ch. 15,16 લોકશાહીમાં સમાનતા પાર્ટ-એ + પાર્ટ-બી રાજ્ય સરકાર      પાર્ટ - એ + પાર્ટ - બી",
        login: { uuid: 7 },
      },
    ],
    SCIENCE: [
      {
        _id: 1,
        marks: 50,
        testDate: "NA",
        chapter: "Chapter - 1 વનસ્પતિમાં પોષણ",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        marks: 30,
        testDate: "NA",
        chapter: "Chapter - 1 વનસ્પતિમાં પોષણ",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        marks: 50,
        testDate: "NA",
        chapter: "Chapter -  પ્રાણીઓમાં પોષણ",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        marks: 30,
        testDate: "NA",
        chapter: "Chapter - 2 પ્રાણીઓમાં પોષણ",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        marks: 50,
        testDate: "NA",
        chapter: "Chapter - 3 ઉષ્મા",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        marks: 25,
        testDate: "NA",
        chapter: "Chapter - 3 ઉષ્મા",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        marks: 50,
        testDate: "NA",
        chapter: "Chapter - 4 એસિડ, બેઈઝ અને ક્ષાર",
        login: { uuid: 7 },
      },
      {
        _id: 8,
        marks: 20,
        testDate: "NA",
        chapter: "Chapter - 4 એસિડ, બેઈઝ અને ક્ષાર",
        login: { uuid: 8 },
      },
      {
        _id: 9,
        marks: 50,
        testDate: "NA",
        chapter: "Chapter - 5 ભૌતિક અને રાસાયણિક ફેરફારો",
        login: { uuid: 9 },
      },
      {
        _id: 10,
        marks: 24,
        testDate: "NA",
        chapter: "Chapter - 5 ભૌતિક અને રાસાયણિક ફેરફારો",
        login: { uuid: 10 },
      },
      {
        _id: 11,
        marks: 50,
        testDate: "NA",
        chapter: "Chapter - 6 સજીવોમાં શ્વસન",
        login: { uuid: 11 },
      },
      {
        _id: 12,
        marks: 25,
        testDate: "NA",
        chapter: "Chapter - 6 સજીવોમાં શ્વસન",
        login: { uuid: 1 },
      },
      {
        _id: 13,
        marks: 50,
        testDate: "31/01/23",
        chapter: "Surprice Test",
        login: { uuid: 13 },
      },
    ],
    SANSKRIT: [
      {
        _id: 1,
        marks: 40,
        testDate: "NA",
        chapter: "1. वन्दना",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        marks: 40,
        testDate: "NA",
        chapter: "2. चित्रपदानि १.१४",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        marks: 40,
        testDate: "NA",
        chapter: "3. मेघो वर्षति",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        marks: 20,
        testDate: "27/10/2023",
        chapter: "4. कोड-क",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        marks: 20,
        testDate: "27/10/2023",
        chapter: "5. हास्ययोगः",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        marks: 40,
        testDate: "27/10/2023",
        chapter: "6. चटक ! चटक !",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        marks: 40,
        testDate: "27/10/2023",
        chapter: "7. संख्या",
        login: { uuid: 7 },
      },
      {
        _id: 8,
        marks: 40,
        testDate: "27/10/2023",
        chapter: "8. विश्वासो नैव कर्तव्यः",
        login: { uuid: 8 },
      },
      {
        _id: 9,
        marks: 40,
        testDate: "27/10/2023",
        chapter: "9. समयः",
        login: { uuid: 9 },
      },
      {
        _id: 10,
        marks: 40,
        testDate: "27/10/2023",
        chapter: "10. आम्लं द्राक्षाफलम्",
        login: { uuid: 10 },
      },
      {
        _id: 11,
        marks: 35,
        testDate: "NA",
        chapter: "11. Surprise Test",
        login: { uuid: 11 },
      },
      {
        _id: 12,
        marks: 70,
        testDate: "NA",
        chapter: "12. Ch. 1 to 10 Grammar",
        login: { uuid: 12 },
      },
    ],
  },
  "STD.8": {
    MATHS: [
      {
        _id: 1,
        marks: 20,
        testDate: "NA",
        chapter: "1. Chapter - 1",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        marks: 30,
        testDate: "NA",
        chapter: "2. Chapter - 2",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        marks: 30,
        testDate: "NA",
        chapter: "3. Chapter - 3",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        marks: 30,
        testDate: "NA",
        chapter: "4. Chapter - 4",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        marks: 30,
        testDate: "NA",
        chapter: "5. Chapter - 5",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        marks: 30,
        testDate: "NA",
        chapter: "6. Chapter - 6",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        marks: 30,
        testDate: "NA",
        chapter: "7. Chapter - 7",
        login: { uuid: 7 },
      },
    ],
    "SOCIAL SCIENCE": [
      {
        _id: 1,
        marks: 40,
        testDate: "NA",
        chapter: "1. Chapter - 1",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        marks: 40,
        testDate: "NA",
        chapter: "2. Chapter - 8",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        marks: 50,
        testDate: "NA",
        chapter: "3. Chapter - 2",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        marks: 50,
        testDate: "NA",
        chapter: "4. Chapter - 3",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        marks: 50,
        testDate: "NA",
        chapter: "5. Chapter - 4",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        marks: 40,
        testDate: "03/08/2023",
        chapter: "6. Chapter - 9",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        marks: 50,
        testDate: "NA",
        chapter: "7. Chapter - 10",
        login: { uuid: 7 },
      },
      {
        _id: 8,
        marks: 40,
        testDate: "NA",
        chapter: "8. Chapter - 11",
        login: { uuid: 8 },
      },
      {
        _id: 9,
        marks: 40,
        testDate: "NA",
        chapter: "9. Chapter - 15",
        login: { uuid: 9 },
      },
      {
        _id: 10,
        marks: 40,
        testDate: "NA",
        chapter: "10. Chapter - 16",
        login: { uuid: 10 },
      },
    ],
    SCIENCE: [
      {
        _id: 1,
        marks: 50,
        testDate: "NA",
        chapter: "1. Chapter - 1 - A",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        marks: 30,
        testDate: "NA",
        chapter: "2. Chapter - 1 - B Question",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        marks: 50,
        testDate: "NA",
        chapter: "3. Chapter - 2 - A",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        marks: 30,
        testDate: "NA",
        chapter: "4. Chapter - 2 - B Question",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        marks: 50,
        testDate: "NA",
        chapter: "5. Chapter - 3 - A",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        marks: 30,
        testDate: "NA",
        chapter: "6. Chapter - 3 - B Question",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        marks: 50,
        testDate: "NA",
        chapter: "7. Chapter - 4 - A",
        login: { uuid: 7 },
      },
      {
        _id: 8,
        marks: 30,
        testDate: "NA",
        chapter: "8. Chapter - 4 - B Question",
        login: { uuid: 8 },
      },
      {
        _id: 9,
        marks: 50,
        testDate: "NA",
        chapter: "9. Chapter - 5 - A",
        login: { uuid: 9 },
      },
      {
        _id: 10,
        marks: 30,
        testDate: "NA",
        chapter: "10. Chapter - 5 - B Question",
        login: { uuid: 10 },
      },
      {
        _id: 11,
        marks: 50,
        testDate: "NA",
        chapter: "11. Chapter - 6 - A",
        login: { uuid: 11 },
      },
      {
        _id: 12,
        marks: 30,
        testDate: "NA",
        chapter: "12. Chapter - 6 - B Question",
        login: { uuid: 12 },
      },
      {
        _id: 13,
        marks: 30,
        testDate: "NA",
        chapter: "13. Surprise Test - Ch. 1, 2, 3, 4",
        login: { uuid: 13 },
      },
    ],
    GUJARATI: [
      {
        _id: 1,
        marks: 50,
        testDate: "NA",
        chapter: "1. Chapter - 1 - A",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        marks: 30,
        testDate: "25/06/2023",
        chapter: "2. Chapter - 1 - B Question",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        marks: 50,
        testDate: "NA",
        chapter: "3. Chapter - 2 - A",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        marks: 25,
        testDate: "10/07/2023",
        chapter: "4. Chapter - 2 - B Question",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        marks: 50,
        testDate: "20/07/2023",
        chapter: "5. Chapter - 3 - A",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        marks: 25,
        testDate: "NA",
        chapter: "6. Chapter - 3 - B Question",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        marks: 50,
        testDate: "NA",
        chapter: "7. Chapter - 4 - A",
        login: { uuid: 7 },
      },
      {
        _id: 8,
        marks: 30,
        testDate: "31/07/2023",
        chapter: "8. Surprise Test - Ch. 1, 2, 3, 4 - B Question",
        login: { uuid: 8 },
      },
      {
        _id: 9,
        marks: 50,
        testDate: "NA",
        chapter: "9. Chapter - 5 - A",
        login: { uuid: 9 },
      },
      {
        _id: 10,
        marks: 30,
        testDate: "NA",
        chapter: "10. Chapter - 5 - B Question",
        login: { uuid: 10 },
      },
      {
        _id: 11,
        marks: 50,
        testDate: "NA",
        chapter: "11. Chapter - 6 - A",
        login: { uuid: 11 },
      },
      {
        _id: 12,
        marks: 30,
        testDate: "NA",
        chapter: "12. Chapter - 6 - B Question",
        login: { uuid: 12 },
      },
    ],
    SANSKRIT: [
      {
        _id: 1,
        marks: 30,
        testDate: "NA",
        chapter: "1. Chapter - 1 to 3",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        marks: 35,
        testDate: "NA",
        chapter: "2. Chapter - 4 to 6",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        marks: 40,
        testDate: "NA",
        chapter: "3. Chapter -  7 to 10",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        marks: 50,
        testDate: "NA",
        chapter: "4. Chapter - 1 to 5",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        marks: 50,
        testDate: "NA",
        chapter: "5. Chapter - 6 to 10",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        marks: 30,
        testDate: "NA",
        chapter: "6. Surprise Test",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        marks: 50,
        testDate: "NA",
        chapter: "7. Chapter - 1 to 10 Grammar",
        login: { uuid: 7 },
      },
      {
        _id: 8,
        marks: 25,
        testDate: "NA",
        chapter: "8. Chapter - 1 to 5 Grammar",
        login: { uuid: 8 },
      },
    ],
  },
  "STD.9": {
    MATHS: [
      {
        _id: 1,
        marks: 30,
        testDate: "02/09/2023",
        chapter: "1. Ch - 1 સંખ્યા પદ્ધતિ",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        marks: 30,
        testDate: "30/06/2023",
        chapter: "2. Ch - 1 Surprise Test",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        marks: 30,
        testDate: "NA",
        chapter: "3. Ch - 2 બહુપદીઓ પાર્ટ-એ",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        marks: 30,
        testDate: "12/08/2023",
        chapter: "4. Ch - 2 બહુપદીઓ પાર્ટ - બી",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        marks: 25,
        testDate: "NA",
        chapter: "5. Ch - 4 દ્વિચલ સુરેખ સમીકરણો",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        marks: 35,
        testDate: "NA",
        chapter: "6. Ch - 3 & 5 (PART - A)",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        marks: 35,
        testDate: "23/09/2023",
        chapter: "7. Ch - 4 & 6 (PART - A)",
        login: { uuid: 7 },
      },
    ],
    "SOCIAL SCIENCE": [
      {
        _id: 1,
        marks: 40,
        testDate: "NA",
        chapter: "Ch - 1 ભારતમાં બ્રિટીશ સત્તાનો ઉદય",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        marks: 40,
        testDate: "NA",
        chapter: "Ch - 2 ્રથમ વિશ્વયુદ્ધ અને રશિયન ક્રાંતિ",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        marks: 40,
        testDate: "NA",
        chapter: "Ch - 3 નૂતન વિશ્વ તરફ પ્રયાણ",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        marks: 40,
        testDate: "NA",
        chapter: "Ch - 4 ભારતની રાષ્ટ્રીય ચળવળો",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        marks: 50,
        testDate: "NA",
        chapter: "Ch - 5 ભારતના રાજ્ય બંધારણનું ઘડતર અને લક્ષણો",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        marks: 40,
        testDate: "NA",
        chapter:
          "Ch - 9 મૂળભુત હક્કો, મૂળભૂત ફરજો અને રાજનીતિના માર્ગદર્શક સિદ્ધાંતો",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        marks: 45,
        testDate: "NA",
        chapter: "Ch - 13 ભારત : સ્થાન, ભૂસ્તરીય રચના અને ભૂપૃષ્ઠ - ૧",
        login: { uuid: 7 },
      },
      {
        _id: 8,
        marks: 50,
        testDate: "NA",
        chapter: "Ch - 14 ભારત : સ્થાન, ભૂસ્તરીય રચના અને ભૂપૃષ્ઠ - ૧૧",
        login: { uuid: 8 },
      },
      {
        _id: 9,
        marks: 40,
        testDate: "NA",
        chapter: "Ch - 15 જળ પરિવાહ",
        login: { uuid: 9 },
      },
    ],
    SCIENCE: [
      {
        _id: 1,
        marks: 50,
        testDate: "NA",
        chapter: "1. Chapter - 1 (Part - A)",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        marks: 30,
        testDate: "NA",
        chapter: "2. Chapter - 1 (Part - B)",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        marks: 50,
        testDate: "NA",
        chapter: "3. Chapter - 2 (Part - A)",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        marks: 30,
        testDate: "NA",
        chapter: "4. Chapter - 2 (Part - B)",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        marks: 50,
        testDate: "NA",
        chapter: "5. Chapter - 5 (Part - A)",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        marks: 30,
        testDate: "NA",
        chapter: "6. Chapter - 5 (Part - B)",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        marks: 50,
        testDate: "NA",
        chapter: "7. Chapter - 8 (Part - A)",
        login: { uuid: 7 },
      },
      {
        _id: 8,
        marks: 30,
        testDate: "NA",
        chapter: "8. Chapter - 8 (Part - B)",
        login: { uuid: 8 },
      },
      {
        _id: 9,
        marks: 50,
        testDate: "NA",
        chapter: "9. Chapter - 7 (Part - A)",
        login: { uuid: 9 },
      },
      {
        _id: 10,
        marks: 30,
        testDate: "NA",
        chapter: "Chapter - 7 (Part - B)",
        login: { uuid: 10 },
      },
      {
        _id: 11,
        marks: 50,
        testDate: "NA",
        chapter: "11. Chapter - 12 (Part - A)",
        login: { uuid: 11 },
      },
      {
        _id: 12,
        marks: 30,
        testDate: "NA",
        chapter: "12. Chapter - 12 (Part - B)",
        login: { uuid: 12 },
      },
      {
        _id: 13,
        marks: 30,
        testDate: "NA",
        chapter: "13. Surprise Test Ch. 1, 5 to 13",
        login: { uuid: 13 },
      },
      {
        _id: 14,
        marks: 60,
        testDate: "NA",
        chapter: "14. Chapter - 1, 2, 5, 7, 12",
        login: { uuid: 14 },
      },
    ],
    ENGLISH: [
      {
        _id: 1,
        marks: 50,
        testDate: "NA",
        chapter: "1. Singular Plural Test",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        marks: 50,
        testDate: "NA",
        chapter: "2. Gender Test",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        marks: 50,
        testDate: "NA",
        chapter: "3. Transformation Test",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        marks: 50,
        testDate: "NA",
        chapter: "4. Unit - 1 Test (Cheetah's Tears)",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        marks: 50,
        testDate: "NA",
        chapter: "5. Unit - 2 Test (Dental Health)",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        marks: 50,
        testDate: "NA",
        chapter: "6. Unit - 3 Test (Mohan's Veena)",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        marks: 50,
        testDate: "NA",
        chapter: "7. Unit - 4 Test (Call of the Hills)",
        login: { uuid: 7 },
      },
      {
        _id: 8,
        marks: 50,
        testDate: "NA",
        chapter: "8. Unit - 5 Test (Rani ki Vav)",
        login: { uuid: 8 },
      },
      {
        _id: 9,
        marks: 50,
        testDate: "NA",
        chapter: "9. 'wh' question test",
        login: { uuid: 9 },
      },
      {
        _id: 10,
        marks: 50,
        testDate: "NA",
        chapter: "10. Surprise Test",
        login: { uuid: 10 },
      },
      {
        _id: 11,
        marks: 50,
        testDate: "NA",
        chapter: "11. Simple Present Test",
        login: { uuid: 11 },
      },
      {
        _id: 12,
        marks: 50,
        testDate: "NA",
        chapter: "12. Simple Past Test",
        login: { uuid: 12 },
      },
      {
        _id: 13,
        marks: 50,
        testDate: "NA",
        chapter: "13. Language Function Test",
        login: { uuid: 13 },
      },
    ],
    SANSKRIT: [
      {
        _id: 1,
        marks: 40,
        testDate: "NA",
        chapter: "1. समर्पनम्",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        marks: 40,
        testDate: "NA",
        chapter: "2. कुलस्य आचारः",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        marks: 40,
        testDate: "NA",
        chapter: "3. परं निधानम् वाः",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        marks: 40,
        testDate: "NA",
        chapter: "4. वकभी विद्यास्थानम्",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        marks: 40,
        testDate: "NA",
        chapter: "5. सुभाषित वैभवः",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        marks: 40,
        testDate: "NA",
        chapter: "6. सर्व यान्तर वसन्ते",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        marks: 40,
        testDate: "NA",
        chapter: "7. संहसिः कार्यसाधिका",
        login: { uuid: 7 },
      },
      {
        _id: 8,
        marks: 40,
        testDate: "NA",
        chapter: "8. काशायाणा काडपराधः",
        login: { uuid: 8 },
      },
      {
        _id: 9,
        marks: 40,
        testDate: "NA",
        chapter: "9. उपकारहस्तु कर्तुव्यः",
        login: { uuid: 9 },
      },
      {
        _id: 10,
        marks: 40,
        testDate: "NA",
        chapter: "10. दुवास्तिकाय सेवानिष्ठा",
        login: { uuid: 10 },
      },
    ],
  },
  "STD.10": {
    ENGLISH: [
      {
        _id: 1,
        marks: 50,
        testDate: "NA",
        chapter: "1. Simple Present Tense",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        marks: 50,
        testDate: "NA",
        chapter: "2. Active - Passive Voice (Simple Present Tense)",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        marks: 50,
        testDate: "NA",
        chapter: "3. Simple Past Tense",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        marks: 50,
        testDate: "NA",
        chapter: "4. Active - Passive Voice Test (Simple Past Tense)",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        marks: 50,
        testDate: "NA",
        chapter: "5. Transformation Test",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        marks: 50,
        testDate: "NA",
        chapter: "6. Unit : 1 Test (Against the Odds)",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        marks: 50,
        testDate: "NA",
        chapter: "7. Unit : 2 Test (The Human Robot)",
        login: { uuid: 7 },
      },
      {
        _id: 8,
        marks: 50,
        testDate: "NA",
        chapter: "8.Unit : 3 Test (An Interview with Arun)",
        login: { uuid: 8 },
      },
      {
        _id: 9,
        marks: 50,
        testDate: "NA",
        chapter: "9. Unit : 4 Test (A Wonderful Creation)",
        login: { uuid: 9 },
      },
      {
        _id: 10,
        marks: 50,
        testDate: "NA",
        chapter: "10. Unit - 5 Test (Playing with Fire)",
        login: { uuid: 10 },
      },
      {
        _id: 11,
        marks: 50,
        testDate: "NA",
        chapter: "11. Unit - 6 Test (I Love You, Teacher)",
        login: { uuid: 11 },
      },
      {
        _id: 12,
        marks: 50,
        testDate: "NA",
        chapter: "12. Surprise Test",
        login: { uuid: 12 },
      },
      {
        _id: 13,
        marks: 50,
        testDate: "NA",
        chapter: "13. Language Function Test",
        login: { uuid: 13 },
      },
      {
        _id: 14,
        marks: 50,
        testDate: "NA",
        chapter: "14. Direct - Indirect Test",
        login: { uuid: 14 },
      },
      {
        _id: 15,
        marks: 50,
        testDate: "NA",
        chapter: "15. Active - Passive Voice Test (Modal Auxiliaries)",
        login: { uuid: 15 },
      },
    ],
    "SOCIAL SCIENCE": [
      {
        _id: 1,
        marks: 120,
        testDate: "12/10/11",
        chapter: "1. ભારતનો વારસો",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        marks: 120,
        testDate: "12/10/11",
        chapter: "2. ભારતનો સાંસ્કૃતિક વારસો, પરંપરાઓ, હસ્ત અને લલિત કલા",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        marks: 120,
        testDate: "12/10/11",
        chapter: "3. ભારતનો સાંસ્કૃતિક વારસો, શિલ્પ અને સ્થાપત્ય",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        marks: 120,
        testDate: "12/10/11",
        chapter: "4. ભારતનો સાહિત્યક વારસો",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        marks: 120,
        testDate: "12/10/11",
        chapter: "5. ભારતનો વિજ્ઞાન અને ટેકનોલોજીનો વારસો",
        login: { uuid: 5 },
      },
      {
        _id: 8,
        marks: 120,
        testDate: "12/10/11",
        chapter: "8. કુદરતી સંશાધનો",
        login: { uuid: 8 },
      },
      {
        _id: 9,
        marks: 120,
        testDate: "12/10/11",
        chapter: "9. વન અને વન્ય જીવ સંસાધન",
        login: { uuid: 9 },
      },
      {
        _id: 10,
        marks: 120,
        testDate: "12/10/11",
        chapter: "10. ભારત કૃષિ",
        login: { uuid: 10 },
      },
      {
        _id: 11,
        marks: 120,
        testDate: "12/10/11",
        chapter: "11. ભારત : જળ સંસાધન",
        login: { uuid: 11 },
      },
      {
        _id: 15,
        marks: 120,
        testDate: "12/10/11",
        chapter: "15. આર્થિક વિકાસ",
        login: { uuid: 15 },
      },
      {
        _id: 16,
        marks: 120,
        testDate: "12/10/11",
        chapter: "16. આર્થિક ઉદારીકરણ અને વૈશ્વિકીકરલ",
        login: { uuid: 16 },
      },
      {
        _id: 17,
        marks: 120,
        testDate: "12/10/11",
        chapter: "17. આર્થિક સમસ્યાઓ અને પડકારો, ગરીબ અને બેરોજગારી",
        login: { uuid: 17 },
      },
    ],
    MATHS: [
      {
        _id: 1,
        marks: 120,
        testDate: "30/06/2023",
        chapter: "Ch - 1. વાસ્તવિક સંખ્યાઓ પાર્ટ - એ",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        marks: 120,
        testDate: "13/06/2023",
        chapter: "Ch - 1. વાસ્તવિક સંખ્યાઓ પાર્ટ - બી",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        marks: 120,
        testDate: "04/05/2023",
        chapter: "Ch - 2. બહુપદીઓ પાર્ટ - બી",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        marks: 120,
        testDate: "13/05/2023",
        chapter: "Ch - 2. બહુપદીઓ પાર્ટ - એ",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        marks: 120,
        testDate: "27/05/2023",
        chapter: "Ch - 3. દ્વિચલ સુરેખ સમીકરણ પુu પાર્ટ - એ, બી",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        marks: 120,
        testDate: "06/08/2023",
        chapter: "Ch - 5. સમાતર શ્રેણી પાર્ટ - એ - 1",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        marks: 120,
        testDate: "24/06/2023",
        chapter: "Ch - 5. સમાંતર શ્રેણી પાર્ટ - બી - 2",
        login: { uuid: 7 },
      },
      {
        _id: 8,
        marks: 120,
        testDate: "05/07/2023",
        chapter: "Ch - 5. સમાંતર શ્રેણી રીવીઝન ટેસ્ટ",
        login: { uuid: 8 },
      },
      {
        _id: 9,
        marks: 120,
        testDate: "22/07/2023",
        chapter: "Ch - 5. સમાંતર શ્રેણી પાર્ટ - બી - 3",
        login: { uuid: 9 },
      },
      {
        _id: 10,
        marks: 120,
        testDate: "06/08/2023",
        chapter: "Ch - 5. સમાંતર શ્રેણી પાર્ટ - બી - 4",
        login: { uuid: 10 },
      },
      {
        _id: 11,
        marks: 120,
        testDate: "16/09/2023",
        chapter: "Ch - 6. ત્રિકોણ - 1",
        login: { uuid: 11 },
      },
      {
        _id: 12,
        marks: 120,
        testDate: "23/09/2023",
        chapter: "Ch - 6. ત્રિકોણ - 2",
        login: { uuid: 12 },
      },
      {
        _id: 13,
        marks: 120,
        testDate: "09/09/2023",
        chapter: "Ch - 7. પામભૂમિતિ પાર્ટ - એ",
        login: { uuid: 13 },
      },
      {
        _id: 14,
        marks: 120,
        testDate: "02/09/2023",
        chapter: "Ch - 7. પામભૂમિતિ પાર્ટ - બી",
        login: { uuid: 14 },
      },
      {
        _id: 15,
        marks: 120,
        testDate: "05/08/2023",
        chapter: "Ch - 13. આંકડાશાસ્ત્ર પાર્ટ - બી",
        login: { uuid: 15 },
      },
      {
        _id: 16,
        marks: 120,
        testDate: "12/08/2023",
        chapter: "Ch - 13. આંકડાશાસ્ત્ર પાર્ટ - એ",
        login: { uuid: 16 },
      },
      {
        _id: 17,
        marks: 120,
        testDate: "18/08/2023",
        chapter: "Ch - 14. સંભાવના પાર્ટ - એ",
        login: { uuid: 17 },
      },
      {
        _id: 18,
        marks: 120,
        testDate: "15/07/2023",
        chapter: "Ch - 14. સંભાવના પાર્ટ-બી",
        login: { uuid: 18 },
      },
    ],
    SCIENCE: [
      {
        _id: 1,
        marks: 50,
        testDate: "NA",
        chapter: "Ch - 1. રાસાયણિક પ્રક્રિયાઓ અને સમીકરણો પાર્ટ-એ",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        marks: 30,
        testDate: "NA",
        chapter: "Ch - 1. રાસાયણિક પ્રક્રિયાઓ અને સમીકરણો પાર્ટ-બી",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        marks: 50,
        testDate: "NA",
        chapter: "Ch - 2. એસિડ, બેઈઝ અને થાર પાર્ટ-એ",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        marks: 30,
        testDate: "NA",
        chapter: "Ch - 2. એસિડ, બેઈઝ અને થાર પાર્ટ-બી",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        marks: 50,
        testDate: "NA",
        chapter: "Ch - 3. ધાતુઓ અને અધાતુઓ પાર્ટ-એ",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        marks: 30,
        testDate: "NA",
        chapter: "Ch - 3. ધાતુઓ અને અધાતુઓ પાર્ટ-બી",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        marks: 50,
        testDate: "NA",
        chapter: "Ch - 5. જૈવિક ક્રિયાઓ પાર્ટ-એ",
        login: { uuid: 7 },
      },
      {
        _id: 8,
        marks: 30,
        testDate: "NA",
        chapter: "Ch - 5. જૈવિક ક્રિયાઓ પાર્ટ-બી",
        login: { uuid: 8 },
      },
      {
        _id: 9,
        marks: 50,
        testDate: "NA",
        chapter: "Ch - 6. પાર્ટ-એ",
        login: { uuid: 9 },
      },
      {
        _id: 10,
        marks: 30,
        testDate: "NA",
        chapter: "Ch - 6. પાર્ટ-બી",
        login: { uuid: 10 },
      },
      {
        _id: 11,
        marks: 50,
        testDate: "NA",
        chapter: "Ch - 7. પાર્ટ-એ",
        login: { uuid: 11 },
      },
      {
        _id: 12,
        marks: 30,
        testDate: "NA",
        chapter: "Ch - 7. પાર્ટ-બી",
        login: { uuid: 12 },
      },
      {
        _id: 13,
        marks: 50,
        testDate: "NA",
        chapter: "Ch - 9. પ્રકાશ પરાવર્તન અને વક્રીભવન પાર્ટ-એ",
        login: { uuid: 13 },
      },
      {
        _id: 14,
        marks: 30,
        testDate: "NA",
        chapter: "Ch - 9. પ્રકાશ પરાવર્તન અને વક્રીભવન પાર્ટ-બી",
        login: { uuid: 14 },
      },
      {
        _id: 15,
        marks: 50,
        testDate: "NA",
        chapter: "Ch - 10. માનવ આંખ અને રંગબેરંગી દુનિયા પાર્ટ-એ",
        login: { uuid: 15 },
      },
      {
        _id: 16,
        marks: 30,
        testDate: "NA",
        chapter: "Ch - 10. માનવ આંખ અને રંગબેરંગી દુનિયા પાર્ટ-બી",
        login: { uuid: 16 },
      },
      {
        _id: 17,
        marks: 50,
        testDate: "NA",
        chapter: "Ch - 13. આપણું પર્યાવરણ પાર્ટ-એ",
        login: { uuid: 17 },
      },
      {
        _id: 18,
        marks: 30,
        testDate: "NA",
        chapter: "Ch - 13. આપણું પર્યાવરણ પાર્ટ-બી",
        login: { uuid: 18 },
      },
      {
        _id: 19,
        marks: 50,
        testDate: "NA",
        chapter: "Ch - 1, 9, 13. પાર્ટ-એ",
        login: { uuid: 19 },
      },
      {
        _id: 20,
        marks: 30,
        testDate: "NA",
        chapter: "Ch - 1, 9, 13. પાર્ટ-બી",
        login: { uuid: 20 },
      },
      {
        _id: 21,
        marks: 30,
        testDate: "31/07/2023",
        chapter: "સરપ્રાઈઝ ટેસ્ટ",
        login: { uuid: 21 },
      },
    ],
    SANSKRIT: [
      {
        _id: 1,
        marks: 40,
        testDate: "NA",
        chapter: "1. सं वदस्वम",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        marks: 40,
        testDate: "NA",
        chapter: "2. युद्धष्टियो विनश्यति",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        marks: 40,
        testDate: "NA",
        chapter: "3. स्वस्थवृत्त समायर",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        marks: 40,
        testDate: "NA",
        chapter: "4. जनादनस्य यद्धिमः सन्देशः",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        marks: 40,
        testDate: "NA",
        chapter: "5. गुणवंती कन्या",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        marks: 40,
        testDate: "NA",
        chapter: "6. काष्ठरन्डः",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        marks: 40,
        testDate: "NA",
        chapter: "7. सुभाषित कुसुमानि",
        login: { uuid: 7 },
      },
      {
        _id: 8,
        marks: 40,
        testDate: "NA",
        chapter: "8. साक्षिभूतः",
        login: { uuid: 8 },
      },
      {
        _id: 9,
        marks: 40,
        testDate: "NA",
        chapter: "9. चक्षुमान अन्ध एव",
        login: { uuid: 9 },
      },
      {
        _id: 10,
        marks: 40,
        testDate: "NA",
        chapter: "10. त्वमेका भवानी",
        login: { uuid: 10 },
      },
    ],
  },
};

export const LABWORK_DATA = {
  "STD.6": {
    SCIENCE: [
      {
        _id: 1,
        practical: "1 મીઠાના દ્રાવણમાંથી મીઠું મેળવવું.",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        practical: "2 મીઠું અને રેતીના મિશ્રણમાંથી ઘટકો છુટા પાડવા.",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        practical: "3 દરિયાના પાણીમાંથી શુદ્ધ પાણી મેળવવું.",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        practical: "4 ાણી અને કેરોસીનના મિશ્રણમાંથી ઘટકો છુટા પાડવા.",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        practical:
          "5 વનસ્પતિનું પ્રકાંડ પાણી અને ખનીજ ક્ષારોના દ્રાવણનું વહન કરે છે.",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        practical:
          "6 વનસ્પતિના પર્ણો બાષ્પોત્સર્જન દ્વારા વધારાનું પાણી બાષ્પ સ્વરૂપે બહાર કાઢ છે.",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        practical:
          "7 પ્રકાશ સંશ્લેષણની ક્રિયા માટે સૂર્યપ્રકાશની હાજરી અનિવાર્ય છે",
        login: { uuid: 7 },
      },
    ],
  },
  "STD.7": {
    SCIENCE: [
      {
        _id: 1,
        practical: "1 બાળરસ સ્ટાર્ચવાળા પદાર્થોનું શર્કરામાં રૂપાંતર કરે છે.",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        practical: "2 ધાતુઓમાં ઉષ્માનું પ્રસરણ ઉષ્માવહનની રીતે થાય છે.",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        practical:
          "3 પ્રવાહી પદાર્થોમાં ઉષ્માનું પ્રસરણ ઉષ્માનયનની રીતે થાય છે.",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        practical:
          "4 કાળા રંગની સપાટી સફેદ રંગની સપાટી કરતાં ઉષ્માનું શોષણ વધુ પ્રમાણમાં કરે છે.",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        practical:
          "5 એસિડ અને બેઈઝ વચ્ચે થતી તટસ્થીકરણની પ્રક્રિયાનો અભ્યાસ કરવો.",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        practical:
          "6એસિડ અને બેઈઝની લિટમસપત્ર, હળદરપત્ર અને જાસૂદના ફુલના દ્રાવણ પર થતી અસરનો અભ્યાસ કરવો,",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        practical:
          "7 કોપર સલ્ફેટના દ્રાવણમાં લોખંડની ખીલી મુકવાથી થતો ફેરફાર રાસાયણિક ફેરફાર છે.",
        login: { uuid: 7 },
      },
      {
        _id: 8,
        practical: "8 ઉચ્છવાસમાં કાર્બનડાયોકસાઈડ વાયુની હાજરી તપાસવી.",
        login: { uuid: 8 },
      },
    ],
  },
  "STD.8": {
    SCIENCE: [
      {
        _id: 1,
        practical:
          "1. પ્રકરણ - ૫ સજીવનો પાયાનો એકમ. ( પ્રયોગ : - ડુંગળીના કોષનું અવલોકન. )",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        practical:
          "2. પ્રકરણ - ૮ બળ અને દબાણ. ( પ્રયોગ : - પદાર્થ પર થતી બળની અસર. )",
        login: { uuid: 2 },
      },
    ],
  },
  "STD.9": {
    SCIENCE: [
      {
        _id: 1,
        practical:
          "1. પ્રકરણ - ૧ આપણી આસપાસના દ્રવ્યો. (પ્રયોગ : - ઘન, પ્રવાહી અને વાયુ પર દબાણની અસર.)",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        practical:
          "2. પ્રકરણ - ૫ સજીવનો પાયાનો એકમ. (પ્રયોગ : - ડુંગળીના કોષોનું અવલોકન.)",
        login: { uuid: 2 },
      },
    ],
  },
  "STD.10": {
    SCIENCE: [
      {
        _id: 1,
        practical:
          "1. પ્રકરણ - 1 રાસાયણિક પ્રક્રિયાઓ. ( પ્રયોગ: -અવક્ષેપન પ્રક્રિયા, રાસાયણિક પ્રક્રિયા તટસ્થીકરણ. )",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        practical:
          "2. પ્રકરણ - 2 એસિડ બેઈઝ અને ક્ષાર. ( પ્રયોગ : - હાઈડ્રોજન વાયુની બનાવટ )",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        practical:
          "3. પ્રકરણ - 9 પ્રકાશ પરાવર્તન અને વક્રીભવન. ( પ્રયોગ : - પ્રિઝમ વડે થતું પ્રકાશનું વક્રીભવન )",
        login: { uuid: 3 },
      },
    ],
  },
};

export const OTHER_DATA = {
  "STD.6": {
    "OTHER ACTIVITIES": [
      {
        _id: 1,
        activityName: "1. ચિત્ર સ્પર્ધા",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        activityName: "2. વેસ્ટમાંથી બેસ્ટ",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        activityName: "3. ટ્રેડિશનલ ડ્રેસ કોમ્પીટીશન",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        activityName: "4. જન્માષ્ટમીની ઉજવણી",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        activityName: "5. રક્ષાબંધનની ઉજવણી",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        activityName: "6. નવરાત્રિની ઉજવણી",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        activityName: "7. મહેંદી સ્પર્ધા",
        login: { uuid: 7 },
      },
      {
        _id: 8,
        activityName: "8. શિક્ષક દિનની ઉજવણી",
        login: { uuid: 8 },
      },
    ],
    "OTHER SUBJECTS": [
      {
        _id: 1,
        description: "દર શનિવારે ",
        period: "12 MONTHS",
        subjectName: "1. જર્નલ સાયન્સ",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        description: "અઠવાડિયામાં બે દિવસ",
        period: "24 MONTHS",
        subjectName: "2. ફંડામેન્ટલ્સ મેથ્સ",
        login: { uuid: 2 },
      },
    ],
    "OTHER TESTS": [
      {
        _id: 1,
        testName: "1. પ્રેકિટસ ટેસ્ટ - 1",
        marks: 80,
        testDate: "24/08/2023",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        testName: "2. પ્રેકિટસ ટેસ્ટ - 2",
        marks: 80,
        testDate: "03/10/2023",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        testName: "3. School First Exam",
        marks: 80,
        testDate: "26/10/2023",
        login: { uuid: 3 },
      },
    ],
  },
  "STD.7": {
    "OTHER ACTIVITIES": [
      {
        _id: 1,
        activityName: "1. ચિત્ર સ્પર્ધા",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        activityName: "2. વેસ્ટમાંથી બેસ્ટ",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        activityName: "3. ટ્રેડિશનલ ડ્રેસ કોમ્પીટીશન",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        activityName: "4. જન્માષ્ટમીની ઉજવણી",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        activityName: "5. રક્ષાબંધનની ઉજવણી",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        activityName: "6. નવરાત્રિની ઉજવણી",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        activityName: "7. મહેંદી સ્પર્ધા",
        login: { uuid: 7 },
      },
      {
        _id: 8,
        activityName: "8. શિક્ષક દિનની ઉજવણી",
        login: { uuid: 8 },
      },
    ],
    "OTHER SUBJECTS": [
      {
        _id: 1,
        description: "દર શનિવારે ",
        period: "12 MONTHS",
        subjectName: "1. જર્નલ સાયન્સ",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        description: "અઠવાડિયામાં બે દિવસ",
        period: "24 MONTHS",
        subjectName: "2. ફંડામેન્ટલ્સ મેથ્સ",
        login: { uuid: 2 },
      },
    ],
    "OTHER TESTS": [
      {
        _id: 1,
        testName: "1. પ્રેકિટસ ટેસ્ટ - 1",
        marks: 80,
        testDate: "24/08/2023",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        testName: "2. પ્રેકિટસ ટેસ્ટ - 2",
        marks: 80,
        testDate: "03/10/2023",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        testName: "3. School First Exam",
        marks: 80,
        testDate: "26/10/2023",
        login: { uuid: 3 },
      },
    ],
  },
  "STD.8": {
    "OTHER ACTIVITIES": [
      {
        _id: 1,
        activityName: "1. ચિત્ર સ્પર્ધા",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        activityName: "2. વેસ્ટમાંથી બેસ્ટ",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        activityName: "3. ટ્રેડિશનલ ડ્રેસ કોમ્પીટીશન",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        activityName: "4. જન્માષ્ટમીની ઉજવણી",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        activityName: "5. રક્ષાબંધનની ઉજવણી",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        activityName: "6. નવરાત્રિની ઉજવણી",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        activityName: "7. મહેંદી સ્પર્ધા",
        login: { uuid: 7 },
      },
      {
        _id: 8,
        activityName: "8. શિક્ષક દિનની ઉજવણી",
        login: { uuid: 8 },
      },
    ],
    "OTHER SUBJECTS": [
      {
        _id: 1,
        description: "દર શનિવારે ",
        period: "12 MONTHS",
        subjectName: "1. જર્નલ સાયન્સ",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        description: "અઠવાડિયામાં બે દિવસ",
        period: "24 MONTHS",
        subjectName: "2. ફંડામેન્ટલ્સ મેથ્સ",
        login: { uuid: 2 },
      },
    ],
    "OTHER TESTS": [
      {
        _id: 1,
        testName: "1. પ્રેકિટસ ટેસ્ટ - 1",
        marks: 80,
        testDate: "24/08/2023",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        testName: "2. પ્રેકિટસ ટેસ્ટ - 2",
        marks: 80,
        testDate: "03/10/2023",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        testName: "3. School First Exam",
        marks: 80,
        testDate: "26/10/2023",
        login: { uuid: 3 },
      },
    ],
  },
  "STD.9": {
    "OTHER ACTIVITIES": [
      {
        _id: 1,
        activityName: "1. ચિત્ર સ્પર્ધા",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        activityName: "2. વેસ્ટમાંથી બેસ્ટ",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        activityName: "3. ટ્રેડિશનલ ડ્રેસ કોમ્પીટીશન",
        login: { uuid: 3 },
      },
      {
        _id: 4,
        activityName: "4. જન્માષ્ટમીની ઉજવણી",
        login: { uuid: 4 },
      },
      {
        _id: 5,
        activityName: "5. રક્ષાબંધનની ઉજવણી",
        login: { uuid: 5 },
      },
      {
        _id: 6,
        activityName: "6. નવરાત્રિની ઉજવણી",
        login: { uuid: 6 },
      },
      {
        _id: 7,
        activityName: "7. મહેંદી સ્પર્ધા",
        login: { uuid: 7 },
      },
      {
        _id: 8,
        activityName: "8. શિક્ષક દિનની ઉજવણી",
        login: { uuid: 8 },
      },
    ],
    "OTHER SUBJECTS": [
      {
        _id: 1,
        description: "દર શનિવારે ",
        period: "12 MONTHS",
        subjectName: "1. જર્નલ સાયન્સ",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        description: "અઠવાડિયામાં બે દિવસ",
        period: "24 MONTHS",
        subjectName: "2. ફંડામેન્ટલ્સ મેથ્સ",
        login: { uuid: 2 },
      },
    ],
    "OTHER TESTS": [
      {
        _id: 1,
        testName: "1. પ્રેકિટસ ટેસ્ટ - 1",
        marks: 50,
        testDate: "24/08/2023",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        testName: "2. પ્રેકિટસ ટેસ્ટ - 2",
        marks: 50,
        testDate: "03/10/2023",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        testName: "3. School First Exam",
        marks: 50,
        testDate: "26/10/2023",
        login: { uuid: 3 },
      },
    ],
  },
  "STD.10": {
    "OTHER ACTIVITIES": [],
    "OTHER SUBJECTS": [],
    "OTHER TESTS": [
      {
        _id: 1,
        testName: "1. પ્રેકિટસ ટેસ્ટ - 1",
        marks: 50,
        testDate: "24/08/2023",
        login: { uuid: 1 },
      },
      {
        _id: 2,
        testName: "2. પ્રેકિટસ ટેસ્ટ - 2",
        marks: 50,
        testDate: "03/10/2023",
        login: { uuid: 2 },
      },
      {
        _id: 3,
        testName: "3. School First Exam",
        marks: 50,
        testDate: "26/10/2023",
        login: { uuid: 3 },
      },
    ],
  },
};

export const STD_6_7_SUBJECT_INFO = [
  "std-6-7-computer.pdf",
  "std-6-7-english.pdf",
  "std-6-7-fundamental-maths.pdf",
  "std-6-7-general-science.pdf",
  "std-6-7-GK.pdf",
  "std-6-7-gujarati.pdf",
  "std-6-7-maths.pdf",
  "std-6-7-sanskrit.pdf",
  "std-6-7-science.pdf",
  "std-6-7-SS.pdf",
];

export const YT_VIDEO_IDS = [
  "mqzZn7hDtcE",
  "WBi3CCn4F58",
  "n8fhX175-JY",
  "wBNkIZZ0zfM",
  "rVyhFCjRMcM",
  "ymDj2jFuP6M",
  "f8mTInuEpP4",
  "Jr7i4deL4HE",
  "_geJT52Ss3g",
  "NIO_6curG3s"
]
