import React from "react";
import pd from "../../assets/images/profile-default.png";
import { Avatar } from "antd";

const PortfolioCard = ({ className, imageUrl, name, pr, passingYear }) => {
  const classes = className;
  return (
    <div
      className={
        classes +
        " w-full max-w-sm rounded-lg py-5 bg-[#F9FAFB] shadow-xl hover:shadow-lg"
      }
    >
      <div className="flex flex-col items-center ">
        <div className="mb-2">
          {imageUrl ? (
            <Avatar src={imageUrl} size={100} />
          ) : (
            <Avatar src={pd} size={100} shape="square" />
          )}
        </div>
        <h5 className="mb-1 lg:text-xl text-lg font-semibold text-blue-900">
          {name}
        </h5>
        <span className="text-sm font-medium text-gray-500 text-center">
          <span className="text-blue-500 font-semibold">PR. {pr} %</span>
          {passingYear && (
            <>
              <br /> {passingYear} Passout
            </>
          )}
        </span>
      </div>
    </div>
  );
};

export default PortfolioCard;
