import React from "react";
import Container from "../../layouts/Container";
import FormSection from "../../components/ContactSections/FormSection";
import LocationSection from "../../components/ContactSections/LocationSection";
import { Divider } from "antd";
import Heading from "../../layouts/Heading";
import SEO from "../../components/SEO";

const ContactusPage = () => {
  return (
    <Container>
      <SEO title="Contact" />
      <div className="my-2">
        <Heading className="text-blue-600 capitalize">Contact Us</Heading>
      </div>
      <Divider />

      <div className="grid gap-3">
        <FormSection />
        <Divider className="border-0" />
        <LocationSection />
      </div>
    </Container>
  );
};

export default ContactusPage;
