import React from "react";
import ContactForm from "../ContactForm";
import Heading from "../../layouts/Heading";

const FormSection = () => {
  return (
    <section className="border-blue-500 bg-blue-50 shadow-md rounded-lg lg:grid lg:grid-cols-2 lg:gap-3 sm:pt-12 pt-5 pb-5 px-5">
      <div className="col-span-1 py-5 sm:pl-5 lg:block">
        <Heading className="text-left w-fit leading-tight">
          <span className="text-blue-600 font-semibold">
            Unlocking Potential:
          </span>
          <br /> Education for All.
        </Heading>
        <div className="mt-5">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Velit saepe
          dolorum, similique, dolorem culpa porro commodi adipisci error
          consequatur pariatur hic sequi aut?
        </div>
      </div>
      <div className="col-span-1 py-5">
        <ContactForm />
      </div>
    </section>
  );
};

export default FormSection;
