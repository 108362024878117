import React from "react";
import Tlogo from "../../assets/images/t-logo.png";
import "./style.css";
import { Link } from "react-router-dom";

const Logo = ({ className = "", url = "#" }) => {
  return (
    <Link to={url} className="flex justify-content-between align-items-center">
      <span className={`logo ${className}`}>
        <img src={Tlogo} alt="logo" className="w-full" />
      </span>
    </Link>
  );
};

export default Logo;
