import React from "react";
import ExpertiesSection from "../../components/ExpertiesSection";
import SEO from "../../components/SEO";

const ExpertiesPage = () => {
  return (
    <>
      <SEO title="Experties" />
      <ExpertiesSection isBg={false} seeMoreBtn={false} />
    </>
  );
};

export default ExpertiesPage;
