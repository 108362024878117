import React from "react";
import Container from "../layouts/Container";
import Heading from "../layouts/Heading";
import ExpertiesCard from "./ExpertiesCard";
import { EXPERTIES } from "../utils/constants";
import ButtonLink from "./ButtonLink";
import { Divider } from "antd";

const ExpertiesSection = ({
  length = EXPERTIES.length,
  isBg = true,
  seeMoreBtn = true,
}) => {
  const cards = [];

  for (let i = 0; i < length; i++) {
    const ex = EXPERTIES[i];

    cards.push(
      <ExpertiesCard key={i} description={ex.description} title={ex.title} />
    );
  }

  return (
    <section className={`py-5 ${isBg ? "lg:bg-[#F9FAFB]" : ""}`}>
      <Container>
        <div className="my-2">
          <Heading className="text-blue-600 capitalize">Our Experties</Heading>
        </div>
        <Divider />
        <div className="my-10">
          <div className="grid gap-5 grid-flow-row grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 my-3">
            {cards}
          </div>
        </div>
      </Container>
      {seeMoreBtn && (
        <div className="flex justify-center items-center mt-20">
          <ButtonLink url="/experties" label="See More ..." />
        </div>
      )}
    </section>
  );
};

export default ExpertiesSection;
