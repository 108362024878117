import React from "react";
import Container from "../../layouts/Container";
import { STATICS } from "../../utils/constants";
import Counter from "../Counter";
import YTVideo from "../YTVideo";
import { YT_VIDEO_IDS } from "../../utils/static";
import ButtonLink from "../ButtonLink";

const CounterSection = () => {

  const videos = [];
  for (let i = 0; i < 8; i++) {
    videos.push(<YTVideo YT_ID={YT_VIDEO_IDS[i]} />);
  }

  return (
    <section className="my-4 bg-[#F9FAFB] py-4">
      <Container>
        <div className="xl:block hidden font-medium w-10/12 mx-auto md:text-6xl mb-5 md:leading-snug text-gray-900">
          <p className="mr-auto w-fit">Building Brighter Futures, </p>
          <p className="ml-auto w-fit">One Lesson at a Time.</p>
        </div>
        <div className="text-left font-medium text-2xl sm:text-4xl md:text-5xl mb-5 leading-10 md:leading-snug block xl:hidden text-gray-900">
          We build stunning best handyman services agency
        </div>

        <div className="w-full flex gap-2">
          <div className="lg:w-4/5 w-full grid xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 justify-center gap-2">
            {videos}
            <div className="flex xl:justify-start md:justify-center sm:justify-start justify-center items-center lg:mt-2 mt-10 w-full">
              <ButtonLink url="/videos" label="See More ..." />
            </div>
          </div>

          <div className="w-1/5 lg:flex hidden">
            <div className="border-red-200 w-full text-right">
              {STATICS.map((data, index) => {
                return (
                  <Counter
                    key={index}
                    start={data.start}
                    end={data.end}
                    duration={data.duration}
                    text={data.text}
                    prefix={data.prefix}
                    suffix={data?.suffix}
                    className="ml-auto"
                  />
                );
              })}
            </div>
          </div>
        </div>

        <div className="border-red-200 mt-5 col-span-3 justify-between lg:hidden grid sm:grid-cols-2 grid-cols-1">
          {STATICS.map((data, index) => {
            return (
              <div
                className="flex justify-center items-center m-2 shadow rounded-2xl"
                key={index}
              >
                <Counter
                  key={index}
                  start={data.start}
                  end={data.end}
                  duration={data.duration}
                  text={data.text}
                  prefix={data.prefix}
                  cntclassName="text-blue-600 text-2xl"
                  txtclassName="text-lg"
                  className="text-center"
                />
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default CounterSection;
