import React from "react";
import { Link, NavLink } from "react-router-dom";
import { FOOTER_MENU1, SCHOOL } from "../../utils/constants";
import Logo from "../Logo";
import { Divider } from "antd";
import Facebook from "../../assets/icons/Facebook";
import Youtube from "../../assets/icons/Youtube";
import { InstagramFilled } from "@ant-design/icons";

const Footer = () => {
  const curretYear = new Date()?.getFullYear() - 1;

  return (
    <div>
      <Divider className="border-0" />
      <Divider className="border-0" />
      <Divider className="border-0" />
      <footer className="bg-white">
        <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
          <div className="md:flex md:justify-between">
            <div className="mb-6 md:mb-0">
              <div className="flex justify-start items-center gap-3 lg:w-3/4 sm:w-1/2 w-full">
                <div className="w-1/5">
                  <Logo url="/" />
                </div>
                <div>
                  <NavLink to={"/"} className={"w-full"}>
                    <span className="text-blue-600 font-semibold capitalize xl:text-xl lg:text-lg text-sm">
                      {SCHOOL.FULLNAME}
                    </span>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center lg:pr-10">
              <div>
                <ul className="text-gray-500 font-medium">
                  {FOOTER_MENU1.map((item, index) => {
                    return (
                      <li className="mb-3 text-center" key={index}>
                        <Link
                          key={index}
                          to={item.url}
                          aria-current="page"
                          className="hover:underline"
                        >
                          {item.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8" />
          <div className="sm:flex sm:items-center sm:justify-between">
            <span className="text-sm text-gray-500 sm:text-center">
              © {curretYear}{" "}
              <Link to="http://127.0.0.1:3000/" className="hover:underline">
                VVG™
              </Link>
              . All Rights Reserved.
            </span>
            <div className="flex mt-4 sm:justify-center items-center sm:mt-0 gap-x-2">
              <Link
                to="#"
                className="text-gray-500 hover:text-gray-900 border-r-2 pr-2"
              >
                {SCHOOL.MOBILE}
              </Link>

              <Link to="#" className="text-gray-500 hover:text-gray-900">
                {SCHOOL.EMAIL}
              </Link>
            </div>
            <div className="md:flex gap-5 hidden">
              <Link to={SCHOOL.SOCIAL_MEDIA.FACEBOOK} target="_blank">
                <Facebook className="text-gray-600 hover:text-gray-500" />
              </Link>
              <Link to={SCHOOL.SOCIAL_MEDIA.INSTAGRAM} target="_blank">
                <InstagramFilled className="text-gray-600 hover:text-gray-500 w-6 h-6" />
              </Link>
              <Link to={SCHOOL.SOCIAL_MEDIA.YOUTUBE} target="_blank">
                <Youtube className="text-gray-600 hover:text-gray-500" />
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
