import client from "./client";

async function getAllPictureCategories(query = "") {
  try {
    const data = await client.get(`/picture-categories?query=${query}`);
    return data;
  } catch (error) {
    throw error;
  }
}

export default { getAllPictureCategories };
