import { std_6_7_subject_info, std_6_test, std_7_test } from "./pdfs";

export function testTableFormater({ data: tests = [] }) {
  const formatted = tests.reduce((acc, curr) => {
    const tests = curr.testContents.map((tc) => {
      let formatedDate = "NA";
      if (tc.testContent?.testDate) {
        formatedDate = new Date(tc.testContent.testDate);
        formatedDate = `${formatedDate.getDate()}/${
          formatedDate.getMonth() + 1
        }/${formatedDate.getFullYear()}`;
      }
      return {
        _id: tc.testContent._id,
        marks: tc.testContent.marks,
        testDate: formatedDate,
        testStatus: tc.testContent.testStatus,
        chapter: tc.testContent.chapter,
        login: { uuid: tc.testContent._id },
      };
    });

    acc = {
      _id: curr._id,
      tests: tests,
      //   subject: curr.subject.subjectName,
      //   subtitle: curr.subtitle,
    };

    return acc;
  }, {});

  return formatted;
}

export function labWorkTableFormater({ data: labWorks = [] }) {
  const formatted = labWorks.reduce((acc, curr) => {
    const labWorks = curr.practicals.map((p) => {
      return {
        _id: p.practical._id,
        practical: p.practical,
        login: { uuid: p.practical._id },
      };
    });

    acc = {
      _id: curr._id,
      practicals: labWorks,
      // subject: curr.subject.subjectName,
      // subtitle: curr.subtitle,
    };

    return acc;
  }, {});

  return formatted;
}

export function otherActivityFormater({ data: stdWiseOtherActivities }) {
  const formatted = stdWiseOtherActivities.reduce((acc, curr) => {
    const stdWiseOtherActivities = curr.otherActivities.map((oa) => {
      return {
        _id: oa.otherActivity._id,
        activityName: oa.otherActivity.activityName,
        login: { uuid: oa.otherActivity._id },
      };
    });

    acc = {
      _id: curr._id,
      others: stdWiseOtherActivities,
    };

    return acc;
  }, {});

  return formatted;
}

export function otherTestFormater({ data: stdWiseOtherTests }) {
  const formatted = stdWiseOtherTests.reduce((acc, curr) => {
    const stdWiseOtherTests = curr.otherTests.map((ot) => {
      let formatedDate = "NA";

      if (ot?.otherTest?.testDate) {
        formatedDate = new Date(ot?.otherTest?.testDate);
        formatedDate = `${formatedDate.getDate()}/${
          formatedDate.getMonth() + 1
        }/${formatedDate.getFullYear()}`;
      }

      return {
        _id: ot.otherTest._id,
        testName: ot.otherTest.testName,
        marks: ot.otherTest.marks,
        testDate: formatedDate,
        login: { uuid: ot.otherTest._id },
      };
    });

    acc = {
      _id: curr._id,
      others: stdWiseOtherTests,
    };

    return acc;
  }, {});

  return formatted;
}

export function otherSubjectFormater({ data: stdWiseOtherSubjects }) {
  const formatted = stdWiseOtherSubjects.reduce((acc, curr) => {
    const stdWiseOtherSubjects = curr.otherSubjects.map((os) => {
      return {
        _id: os.otherSubject._id,
        description: os.otherSubject.description,
        period: os.otherSubject.period,
        subjectName: os.otherSubject.subjectName,
        login: { uuid: os.otherSubject._id },
      };
    });

    acc = {
      _id: curr._id,
      others: stdWiseOtherSubjects,
    };

    return acc;
  }, {});

  return formatted;
}

export function getServerError(error) {
  /*
    console.log(error.response.status); //==> 400
    console.log(error.response.data); // ==> object
    console.log(error.response.data.status); ==> 'FAILED' or. 'OK'
    console.log(error.response.data.data.error); ==> message from server side
  */

  return {
    statusCode: error.response.status,
    statusText: error.response.data.status,
    errorMessage: error.response.data.data.error,
  };
}

export function getSubjectInfo (stdNo, subject){
  if(stdNo === 6 || stdNo === 7){
    if(subject === "computer"){return std_6_7_subject_info.std_6_7_computer }
    if(subject === "english"){ return std_6_7_subject_info.std_6_7_english }
    if(subject === "fundamental_maths"){ return std_6_7_subject_info.std_6_7_fundamental_maths }
    if(subject === "general_science"){ return std_6_7_subject_info.std_6_7_general_science }
    if(subject === "gk"){ return std_6_7_subject_info.std_6_7_gk }
    if(subject === "gujarati"){ return std_6_7_subject_info.std_6_7_gujarati }
    if(subject === "maths"){ return std_6_7_subject_info.std_6_7_maths }
    if(subject === "sanskrit"){ return std_6_7_subject_info.std_6_7_sanskrit }
    if(subject === "science"){ return std_6_7_subject_info.std_6_7_science }
    if(subject === "social_science"){ return std_6_7_subject_info.std_6_7_ss }
  }
}

export function getTest (stdNo, subject){
  if(stdNo === 6){
    if(subject === "maths"){ return std_6_test.std_6_maths_test }
  }
  if(stdNo === 7){
    if(subject === "maths"){ return std_7_test.std_7_maths_test }
  }
}

export const makeLabel = (text)=> text.split("_").join(" ").toUpperCase();