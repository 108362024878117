import React from "react";
import Container from "../../layouts/Container";
import { Link } from "react-router-dom";
import spv from "../../assets/videos/School_Promotional_Video.mp4";
import Video from "../Video";
import { home_content } from "../../utils/static";
import BlockQuote from "../BlockQuote";

const HomeDescriptionSection = () => {
  const homeLines = home_content.split("##");
  return (
    <section className="bg-blue-gradiant">
      <Container>
        <div className="rounded-lg lg:grid lg:grid-cols-2 lg:gap-3 lg:pt-12 sm:pt-8 pb-5 lg:px-5 ">
          <div className="col-span-1 py-5 mr-auto">
            <div className="lg:text-left text-center font-bold sm:text-5xl text-2xl lg:w-fit w-full leading-tight">
              Inspire, Innovate,
              <br className="lg:block hidden" />
              &nbsp;
              <span className="text-blue-600 font-semibold">Ignite</span>
            </div>
            <div className="mt-5 mr-auto text-center">
              <BlockQuote caption={false}>
                {homeLines.map((line, index) => {
                  return (
                    <span
                      key={index}
                      className="mb-2 text-cyan-800 font-bold lg:text-xl text-lg capitalize"
                    >
                      {line} <br />
                    </span>
                  );
                })}
              </BlockQuote>
            </div>
            <div className="mt-10 text-center">
              <Link to={"/contact"}>
                <button
                  type="button"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center"
                >
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
          <div className="lg:block col-span-1 py-5 relative flex justify-center">
            <Video src={spv} />
            <div className="bg-blue-700 text-white font-bold rounded-[50%] lg:flex w-[130px] h-[130px] absolute -right-20 top-10 border-white border-4 hidden">
              <p className="text m-auto">9.3 Ratings</p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default HomeDescriptionSection;
