import React from "react";
import Container from "../../layouts/Container";
import BlockQuote from "../BlockQuote";
import { SCHOOL } from "../../utils/constants";

const BlockQuoteSection = () => {
  {
    /* bg-[${bg1}] */
  }

  return (
    <section className="border-blue-400 py-10 ">
      <Container>
        <BlockQuote author={SCHOOL.SHORTFORM} organization={SCHOOL.FULLFROM}>
          <span className="text-blue-600 font-semibold">
            {" "}
            Crafting Bright Futures:
          </span>{" "}
          <br />
          Where Every Student's Potential Shines.
        </BlockQuote>
      </Container>
    </section>
  );
};

export default BlockQuoteSection;
