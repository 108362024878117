import React from "react";
import pd from "../../assets/images/profile-default.png";
import { Avatar, Rate } from "antd";

const TestimonialCard = ({
  feedback = "",
  rating = 0.0,
  reviewerImg,
  reviewer = "",
  category = "",
  subline = "",
  passingYear = "",
}) => {
  return (
    <div className="flex flex-col overflow-hidden shadow-xl hover:shadow-lg">
      <div className="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
        <div className="flex-1">
          <div className="flex items-center">
            <Rate allowHalf disabled value={rating} />
          </div>

          <blockquote className="flex-1 mt-8">
            <p className="lg:text-lg text-base leading-relaxed text-gray-900 font-pj">
              “{feedback}”
            </p>
          </blockquote>
        </div>

        <div className="flex items-center mt-8">
          {reviewerImg ? (
            <Avatar src={reviewerImg} size={"large"} />
          ) : (
            <img
              className="flex-shrink-0 object-cover rounded-full w-11 h-11"
              src={pd}
              alt={reviewer}
            />
          )}
          <div className="ml-4">
            <p className="sm:text-base text-sm font-bold text-gray-900 font-pj">
              {category === "senior" && (
                <span className="text-base font-medium text-blue-900">
                  {subline}.{" "}
                </span>
              )}
              <span className="sm:text-base text-sm font-medium text-blue-800">
                {reviewer}
              </span>{" "}
              &nbsp;
              <span className="capitalize sm:text-sm text-xs text-gray-500">
                ({category === "senior" ? "Parent" : "Student"})
              </span>
            </p>
            <p className="mt-0.5 text-sm font-pj text-gray-600 capitalize">
              {passingYear}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
