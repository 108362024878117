import React from "react";
import pd from "../../assets/images/profile-default.png";
import { Avatar } from "antd";

const TeacherCard = ({
  teacherName = "",
  teacherImage,
  teacherMobileNo = "",
  introduction = "",
  subjects = [],
  direction = "L_TO_R",
}) => {
  const bioLines = introduction.split("\\n");
  return (
    <div
      className={`rounded-md mb-10 shadow-xl hover:shadow-lg p-10 flex lg:flex-row flex-col justify-between lg:w-2/3 w-full gap-10 bg-gray-50 ${
        direction === "R_TO_L" ? "lg:flex-row-reverse lg:ml-auto" : ""
      }`}
    >
      <div className="lg:w-1/3 w-full flex justify-center h-fit">
        {teacherImage ? (
          <Avatar src={teacherImage} size={200} shape="square" />
        ) : (
          <Avatar src={pd} size={100} shape="square" />
        )}
      </div>

      <div className="lg:w-2/3 w-full flex flex-col justify-between gap-4">
        <div className="h-full flex flex-col">
          <ul className="mb-5 lg:text-base text-sm lg:text-start text-center">
            {bioLines.map((line, index) => {
              return (
                <li
                  key={index}
                  className="mb-1 text-cyan-800 font-medium capitalize"
                >
                  {line}
                </li>
              );
            })}
          </ul>

          <div className="text-blue-500 font-semibold lg:text-base sm:text-sm text-xs flex flex-wrap gap-2 mt-auto">
            {subjects?.map((s) => {
              return (
                <span
                  className="font-semibold text-blue-900 border-l-4 border-blue-200 rounded-tl rounded-bl px-1 py-0.5"
                  key={s?._id}
                >
                  {s?.subject?.subjectName}
                </span>
              );
            })}
          </div>
        </div>

        <div className="text-right mt-auto">
          ~{" "}
          <span className="text-blue-500 font-semibold text-base capitalize">
            {teacherName}
          </span>
          <br />{" "}
          <span className="text-blue-900 italic font-medium text-sm">
            {teacherMobileNo}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TeacherCard;
