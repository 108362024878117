import LightGallery from "lightgallery/react";
import "./style.css";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import GalleryPicturesApis from "../../api/gallery-pictures";

// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Empty, Spin } from "antd";

export default function Gallery({ cId }) {
  const [images, setImages] = useState([]);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(null);

  async function fetchGalleryPicturesByCategory(cId) {
    setLoad(true);
    try {
      const galleryPictureData =
        await GalleryPicturesApis.getGalleryPicturesByCategoryId(cId);

      if (galleryPictureData.data.data?.length === 0) {
        setLoad(false);
        setImages([]);
        return;
      }
      setImages(galleryPictureData.data.data);
      setLoad(false);
    } catch (error) {
      if (error.response.status === 500) {
        setLoad(false);
        setImages([]);
        setError("Internal Server Error");
        return;
      }
    }
  }

  useEffect(() => {
    fetchGalleryPicturesByCategory(cId);
  }, [cId]);

  const onInit = () => {
    // console.log("lightGallery has been initialized");
  };

  if (images.length === 0 && error) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  if (images.length === 0 && load && !error) {
    return (
      <div className="min-h-52">
        <Spin className="w-full min-h-20 flex items-center justify-center" />
      </div>
    );
  }

  if (images.length === 0 && !load && !error) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  return (
    <div className="gallery-body">
      <LightGallery onInit={onInit} speed={500} plugins={[lgThumbnail, lgZoom]}>
        {images?.map((i) => {
          return (
            <Link key={i?._id} to={i?.image}>
              <img alt={`${i?.category?.category}`} src={i?.image} />
            </Link>
          );
        })}
      </LightGallery>
    </div>
  );
}
