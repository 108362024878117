import client from "./client";

async function getGalleryPicturesByCategoryId(categoryId) {
  try {
    const data = await client.get(`/gallery-pictures/${categoryId}`);
    return data;
  } catch (error) {
    throw error;
  }
}

export default { getGalleryPicturesByCategoryId };
