import React, { useEffect, useState } from "react";
import TeacherCard from "../TeacherCard";
import Heading from "../../layouts/Heading";
import Container from "../../layouts/Container";
import TeacherApis from "../../api/teachers";
import { Empty, Spin } from "antd";

const TeacherSection = () => {
  const [teachers, setTeachers] = useState([]);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(null);

  async function fetchData() {
    setLoad(true);
    try {
      const { data } = await TeacherApis.getAllTeachers();
      setTeachers(data?.data);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      setError("Teacher's details don't found");
      return;
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <section className="my-10">
      <Container>
        <div className="text-left font-medium text-2xl sm:text-4xl md:text-5xl mb-5 leading-10 md:leading-snug text-gray-900">
          Igniting Potential, Shaping Tomorrow: Education for Every Student.
        </div>

        <Heading className="text-blue-600 capitalize">our teachers</Heading>
      </Container>
      <div className="my-4 bg-blue-gradiant py-4">
        <Container>
          {teachers.length === 0 && load && !error && (
            <div className="min-h-52">
              <Spin className="w-full min-h-20 flex items-center justify-center" />
            </div>
          )}
          {teachers.length === 0 && !load && error ? (
            <Empty className="md:hidden" image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            <div className="my-3">
              {teachers?.map((teacher, index) => {
                return (
                  <TeacherCard
                    key={teacher?._id}
                    teacherName={teacher?.teacherName}
                    introduction={teacher?.introduction}
                    teacherImage={teacher?.teacherImage}
                    subjects={teacher?.subjects}
                    teacherMobileNo={teacher?.teacherMobileNo}
                    direction={index % 2 === 0 ? "L_TO_R" : "R_TO_L"}
                  />
                );
              })}
            </div>
          )}
        </Container>
      </div>
    </section>
  );
};

export default TeacherSection;
