import client from "./client";

async function getTopNTestimonialsByCategory(category, n) {
  try {
    const data = await client.get(`/testimonials/category/${category}/n/${n}`);
    return data;
  } catch (error) {
    throw error;
  }
}

export default { getTopNTestimonialsByCategory };
