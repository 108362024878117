import { createBrowserRouter } from "react-router-dom";
import MainLayout, { loadMainLayoutData } from "../layouts/MainLayout";

// PAGES
import HomePage from "../pages/HomePage";
import AboutusPage from "../pages/AboutusPage";
import ContactusPage from "../pages/ContactusPage";
import GalleryPage, { loadGalleryPictures } from "../pages/GalleryPage";
import StandardPage, { loadStdardWiseData } from "../pages/StandardPage";
import TestimonialPage from "../pages/TestimonialPage";
import ExpertiesPage from "../pages/ExpertiesPage";
import ResultsPage from "../pages/ResultsPage";
import Error404Page from "../pages/Error/404";
import PracticalGalleryPage, { loadPracticalGalleryPictures } from "../pages/PracticalGalleryPage";
import VideosPage from "../pages/VideosPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    loader: loadMainLayoutData,
    children: [
      {
        path: "",
        element: <HomePage />,
      },
      {
        path: "/about",
        element: <AboutusPage />,
      },
      {
        path: "/contact",
        element: <ContactusPage />,
      },
      {
        path: "/gallery",
        element: <GalleryPage />,
        loader: loadGalleryPictures,
      },
      {
        path: "/practicals",
        element: <PracticalGalleryPage />,
        loader: loadPracticalGalleryPictures,
      },
      {
        path: "/videos",
        element: <VideosPage />,
      },
      {
        path: "/standard/:stdNo",
        element: <StandardPage />,
        loader: loadStdardWiseData,
      },
      {
        path: "/testimonials",
        element: <TestimonialPage />,
      },
      {
        path: "/experties",
        element: <ExpertiesPage />,
      },
      {
        path: "/results",
        element: <ResultsPage />,
      },
    ],
  },
  {
    path: "*",
    element: <Error404Page />,
  },
]);

export default router;
