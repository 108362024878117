import React, { useEffect, useState } from "react";
import TestimonialApis from "../api/testimonials";
import TestimonialCard from "./TestimonialCard";
import Heading from "../layouts/Heading";
import ButtonLink from "./ButtonLink";
import { Divider, Empty, Spin } from "antd";

const TestimonialSection = ({
  category = "junior",
  n = 3,
  isBackgorund = true,
  seeMoreBtn = true,
  heading,
  subtitle = true,
}) => {
  const [testimonials, setTestimonials] = useState([]);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(null);

  async function fetchData() {
    try {
      setLoad(true);
      const { data } = await TestimonialApis.getTopNTestimonialsByCategory(
        category,
        n
      );
      setTestimonials(data?.data);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      setError("Testimonials doesn't found");
      return;
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <section className="py-12 sm:py-16 lg:py-20">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex flex-col items-center">
          <div className="text-center">
            {subtitle && (
              <p className="lg:text-lg sm:text-base text-sm font-medium text-gray-600 font-pj">
                Hear What Our Community Has to Say!
              </p>
            )}

            {!heading && (
              <Heading className="text-blue-600">
                Our happy {category === "junior" ? "students" : "reviewers"} say
                about us
              </Heading>
            )}
            {heading && <Heading className="text-blue-600">{heading}</Heading>}
            <Divider />
          </div>
          {testimonials.length === 0 && load && !error && (
            <div className="min-h-52">
              <Spin className="w-full min-h-20 flex items-center justify-center" />
            </div>
          )}

          {testimonials.length === 0 && !load && error ? (
            <Empty className="md:hidden" image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            <div className="relative mt-10 md:mt-24 md:order-2">
              {isBackgorund && (
                <div className="absolute -inset-x-1 inset-y-16 md:-inset-x-2 md:-inset-y-6">
                  <div
                    className="w-full h-full max-w-5xl mx-auto rounded-3xl opacity-30 blur-lg filter"
                    style={{
                      background:
                        "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)",
                    }}
                  ></div>
                </div>
              )}

              <div className="relative grid max-w-lg grid-cols-1 gap-6 mx-auto md:max-w-none lg:gap-10 xl:grid-cols-3 lg:grid-cols-2">
                {testimonials?.map((testimonial) => {
                  return (
                    <TestimonialCard
                      key={testimonial?._id}
                      feedback={testimonial?.feedback}
                      passingYear={testimonial?.passingYear}
                      rating={testimonial?.rating}
                      reviewer={testimonial?.reviewerName}
                      reviewerImg={testimonial?.reviewerImage}
                      subline={testimonial?.subline}
                      category={testimonial?.category}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      {testimonials.length > 0 && !load && !error && seeMoreBtn && (
        <div className="flex justify-center items-center mt-20">
          <ButtonLink url="/testimonials" label="See More ..." />
        </div>
      )}
    </section>
  );
};

export default TestimonialSection;
