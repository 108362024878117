import React from "react";
import { useLoaderData } from "react-router-dom";
import { Divider, Tabs } from "antd";
import Container from "../../layouts/Container";
import Heading from "../../layouts/Heading";
import SEO from "../../components/SEO";
import { std_6_7_subjects } from "../../utils/pdfs";
import PdfComp from "../../components/PDF";
import { getSubjectInfo, getTest, makeLabel } from "../../utils/helpers";

const StandardPage = () => {
  const { stdNo, subjects, defaultKey } = useLoaderData();

  const testTabItems = subjects?.map((subject) => {

    const testPDF = getTest(stdNo, subject);
    const subjectInfoPDF = getSubjectInfo(stdNo, subject);

    return {
      key: subject,
      label: makeLabel(subject),
      children: (
        <div className="sm:rounded-lg sm:py-4 sm:px-4">
          { testPDF && <TestPDF testPDF={testPDF}/> }
          { subjectInfoPDF && <SubjectInfo subjectInfoPDF={subjectInfoPDF}/> }
        </div>
      ),
    };
  });

  return (
    <Container>
      <SEO title={`Standard ${stdNo}`} />

      <div className="my-2">
        <Heading className="text-blue-600 capitalize">Standard {stdNo}</Heading>
      </div>
      <Divider className="border-0" />
      <Tabs
        type="card"
        items={testTabItems}
        defaultActiveKey={defaultKey}
      />
    </Container>
  );
};

export default StandardPage;

function TestPDF({testPDF}) {
  return(
    <>
      <div className="my-2">
        <Heading className="text-blue-600 capitalize text-start">Tests</Heading>
      </div>
      <Divider/>
      <div className="flex justify-center items-center sm:bg-slate-50 rounded-lg py-4 px-2">
        <PdfComp pdfFile={testPDF} />
      </div>
      <Divider className="border-0"/>
    </>
  );
}

function SubjectInfo({subjectInfoPDF}) {
  return(
    <>
      <div className="my-2">
        <Heading className="text-blue-600 capitalize text-start">Subject Info.</Heading>
      </div>
      <Divider/>
      <div className="flex justify-center items-center sm:bg-slate-50 rounded-lg py-4 px-2">
        <PdfComp pdfFile={subjectInfoPDF} />
      </div>
    </>
  );
}

export async function loadStdardWiseData({ params }) {
  let { stdNo } = params;
  stdNo = +stdNo;

  let subjects = [];
  if(stdNo === 6 || stdNo === 7){
    subjects = std_6_7_subjects;
  }
  let defaultKey = subjects[0];

  return {
    stdNo,
    subjects,
    defaultKey,
  };
}
