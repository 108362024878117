import client from "./client";

async function getAllStandards() {
  try {
    const data = await client.get("/standards");
    return data;
  } catch (error) {
    throw error;
  }
}

export default { getAllStandards };
