import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
import img404 from "../../../assets/images/404.png";

const Error404 = () => {
  return (
    <div className="body-e404">
      <section>
        <div className="container">
          <div className="text">
            <h1>Page Not Found</h1>
            <p>
              We can't seem to find the page you're looking for. Please check
              the URL for any typos.
            </p>

            <ul className="menu">
              <li>
                <Link to="/">Go to Homepage</Link>
              </li>

              <li>
                <Link to="/contact">Contact support</Link>
              </li>
            </ul>
          </div>
          <div>
            <img className="image" src={img404} alt={"404"} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Error404;
