import React from "react";
import { Divider } from "antd";
import HomeDescriptionSection from "../../components/HomeSections/HomeDescriptionSection";
import CounterSection from "../../components/HomeSections/CounterSection";
import TestimonialSection from "../../components/TestimonialSection";
import ExpertiesSection from "../../components/ExpertiesSection";
import SEO from "../../components/SEO";
import Container from "../../layouts/Container";
import BlockQuoteSection from "../../components/AboutUsSections/BlockQuoteSection";
import TeacherSection from "../../components/AboutUsSections/TeacherSection";

const Homepage = () => {
  return (
    <div>
      <SEO title="Home" />
      <HomeDescriptionSection />

      <Divider className="border-0" />
      <TeacherSection />

      <Divider className="border-0" />
      <ExpertiesSection length={5} />

      <Divider className="border-0" />
      <BlockQuoteSection />

      <Divider className="border-0" />
      <CounterSection />

      <Divider className="border-0" />
      <Container>
        <TestimonialSection category="senior" />
      </Container>
    </div>
  );
};

export default Homepage;
