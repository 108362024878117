import { useLoaderData } from "react-router-dom";
import PictureCategoriesApis from "../../api/picture-categories";
import GalleryTemplate from "../../templates/GalleryTemplate";
import Container from "../../layouts/Container";
import SEO from "../../components/SEO";
import { Divider, Empty } from "antd";
import Heading from "../../layouts/Heading";

export default function PracticalGalleryPage() {
  const { categories, defaultCategoryActiveKey, error } = useLoaderData();

  if (error) {
    return (
      <Container>
        <Divider className="border-0" />
        <Heading className="text-blue-600 capitalize">Practicals</Heading>
        <Divider />
        {/* <Alert type="error" showIcon message={error} /> */}
        <Empty className="md:hidden" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </Container>
    );
  }
  return (
    <Container>
      <SEO title="Practical's Gallery" />

      <GalleryTemplate
        title="Practicals"
        categories={categories}
        defaultCategoryActiveKey={defaultCategoryActiveKey}
      />
    </Container>
  );
}

export async function loadPracticalGalleryPictures() {
  try {
    const PictureCategoriesData =
      await PictureCategoriesApis.getAllPictureCategories("practicals");
    const defaultCategoryActiveKey = PictureCategoriesData?.data?.data[0]?._id;

    return {
      categories: PictureCategoriesData?.data?.data,
      defaultCategoryActiveKey,
    };
  } catch (error) {
    return {
      error: "Internal Server Error",
    };
  }
  return null;
}
