import React from "react";
import { Divider } from "antd";
import TeacherSection from "../../components/AboutUsSections/TeacherSection";
import ExpertiesSection from "../../components/ExpertiesSection";
import BlockQuoteSection from "../../components/AboutUsSections/BlockQuoteSection";
import AboutDescriptionSection from "../../components/AboutUsSections/AboutDescriptionSection";
import ResultSection from "../../components/ResultSection";
import TestimonialSection from "../../components/TestimonialSection";
import Container from "../../layouts/Container";
import SEO from "../../components/SEO";

const AboutusPage = () => {
  return (
    <div>
      <SEO title="About" />
      <AboutDescriptionSection />

      <Divider className="border-0" />
      <BlockQuoteSection />

      <Divider className="border-0" />
      <a href="#experties"></a>
      <ExpertiesSection length={6} />

      <Divider className="border-0" />
      <TeacherSection />

      <Divider className="border-0" />
      <Container>
        <div className="text-left font-medium text-2xl sm:text-4xl md:text-5xl mb-5 leading-10 md:leading-snug text-gray-900">
          Unlocking Success, Building Futures: Achievements of Every Student.
        </div>
      </Container>

      <Container>
        <ResultSection years={[2021]} />
      </Container>

      <Divider className="border-0" />
      <Container>
        <TestimonialSection />
      </Container>
    </div>
  );
};

export default AboutusPage;
