import { Divider, Tabs } from "antd";
import React, { useState } from "react";
import Heading from "../layouts/Heading";
import Gallery from "../components/Gallery";

const GalleryTemplate = ({title="gallery", categories, defaultCategoryActiveKey }) => {
  const [categoryActiveKey, setCategoryActiveKey] = useState(
    defaultCategoryActiveKey
  );

  const tabItems = categories?.map((category) => {
    return {
      label: category?.category,
      key: category?._id,
      children: <Gallery cId={categoryActiveKey} />,
    };
  });

  const onCategoryChange = (categoryActiveKey) => {
    setCategoryActiveKey(categoryActiveKey);
  };

  return (
    <div>
      <Divider className="border-0" />
      <Heading className="text-blue-600 capitalize">{title}</Heading>
      <Divider />

      <Tabs
        onChange={onCategoryChange}
        type="card"
        items={tabItems}
        defaultActiveKey={defaultCategoryActiveKey}
      />
    </div>
  );
};

export default GalleryTemplate;
