import React from "react";
import Container from "../../layouts/Container";
import { Divider } from "antd";
import TestimonialSection from "../../components/TestimonialSection";
import SEO from "../../components/SEO";

const TestimonialPage = () => {
  return (
    <Container>
      <SEO title="Testimonial" />

      <TestimonialSection
        category="junior"
        n={-1}
        isBackgorund={false}
        seeMoreBtn={false}
      />
      <Divider className="border-0" />
      <TestimonialSection
        category="senior"
        n={-1}
        isBackgorund={false}
        seeMoreBtn={false}
        heading={"Our happy parents say about us"}
      />
    </Container>
  );
};

export default TestimonialPage;
