import React from "react";

const BlockQuote = ({
  children,
  author = "",
  organization = "[organization]",
  caption = true,
}) => {
  return (
    <figure className="max-w-screen-md mx-auto text-center">
      <svg
        className="w-10 h-10 mx-auto mb-3 text-blue-400"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 18 14"
      >
        <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
      </svg>
      <blockquote>
        <p className="text-lg sm:text-2xl italic font-medium text-gray-900 ">
          {children}
        </p>
      </blockquote>
      {caption && (
        <figcaption className="flex items-center justify-center mt-6 space-x-3 rtl:space-x-reverse">
          <div className="flex items-center sm:flex-row flex-col sm:divide-x-2 rtl:divide-x-reverse sm:divide-blue-500 sm:border-none border-l-2 border-blue-500">
            <cite className="pe-3 font-medium text-gray-900 capitalize">
              {author}
            </cite>
            <cite className="ps-3 text-sm text-gray-500 capitalize">
              at {organization}
            </cite>
          </div>
        </figcaption>
      )}
    </figure>
  );
};

export default BlockQuote;
