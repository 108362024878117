import client from "./client";

async function getAllResultsByYear(passingYear) {
  try {
    const data = await client.get(`/results/passingYear/${passingYear}`);
    return data;
  } catch (error) {
    throw error;
  }
}

export default { getAllResultsByYear };
