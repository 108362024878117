import client from "./client";

async function getAllTeachers() {
  try {
    const data = await client.get("/teachers");
    return data;
  } catch (error) {
    throw error;
  }
}

export default { getAllTeachers };
