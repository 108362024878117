import { Link } from "react-router-dom";

const STATICS = [
  {
    start: 1,
    end: 20,
    duration: 1,
    text: "Experience",
    prefix: "+ ",
  },
  {
    start: 1,
    end: 3,
    duration: 1,
    text: "Branches",
    prefix: "+ ",
  },
  {
    start: 1,
    end: 95,
    duration: 1,
    text: "Average Result",
    prefix: "+ ",
    suffix: " %",
  },
];

const SCHOOL = {
  FULLNAME: "VVG - Vidhyadhan Vikas Group",
  SHORTFORM: "VVG",
  FULLFROM: "Vidhyadhan Vikas Group",
  MOBILE: " +91 94081 01842",
  EMAIL: "vidhyadhanvikas@gmail.com",
  SOCIAL_MEDIA: {
    FACEBOOK:
      "https://www.facebook.com/share/FuUivBCrkMEdeEzv/?mibextid=qi2Omg",
    INSTAGRAM: "https://www.instagram.com/vvg_godhra/",
    YOUTUBE: "https://www.youtube.com/channel/UClFi9MpRzjsthKOiuTONDTw",
  },
};

const NAVBAR_ITEMS = [
  {
    key: "Home",
    path: "/",
    label: <Link to={"/"}>Home</Link>,
  },
  {
    key: "About us",
    path: "/about",
    label: <Link to={"/about"}>About us</Link>,
  },
  {
    key: "Practicals",
    path: "/practicals",
    label: <Link to={"/practicals"}>Practicals</Link>,
  },
  {
    key: "Memories",
    path: "/gallery",
    label: <Link to={"/gallery"}>Memories</Link>,
  },
  {
    key: "Videos",
    path: "/videos",
    label: <Link to={"/videos"}>Videos</Link>,
  },
  {
    key: "Testimonials",
    path: "/testimonials",
    label: <Link to={"/testimonials"}>Testimonials</Link>,
  },
  {
    key: "Results",
    path: "/results",
    label: <Link to={"/results"}>Results</Link>,
  },
  {
    key: "Experties",
    path: "/experties",
    label: <Link to={"/experties"}>Experties</Link>,
  },
];

const academics = [
  "results", "experties"
];

const ACADEMICS_ITEMS = (academics, toggleMenu = ()=>{})=>{
  const items = academics.map((academic, index) => {
    return {
      key: index,
      path: `${academic}`,
      label: (
        <Link
          to={`${academic}`}
          rel="noopener noreferrer"
          onClick={toggleMenu}
          className="capitalize"
        >
          {academic}
        </Link>
      ),
    };
  });

  return items;  
}

const STANDARD_ITEMS = (standards = [], toggleMenu= ()=>{}) => {
  const items = standards.map((standard, index) => {
    return {
      key: index,
      path: `standard/${standard.standardNo}`,
      label: (
        <Link
          to={`standard/${standard.standardNo}`}
          rel="noopener noreferrer"
          onClick={toggleMenu}
        >
          Standard &nbsp;{standard.standardNo}
        </Link>
      ),
    };
  });

  return items;
};

const EXPERTIES = [
  {
    imageUrl: "url_to_image1",
    title: "STEM Education",
    description:
      "We offer comprehensive STEM education programs to foster critical thinking, problem-solving, and innovation among our students.",
  },
  {
    imageUrl: "url_to_image2",
    title: "Arts Integration",
    description:
      "Our curriculum integrates arts education across all subjects to enhance creativity, expression, and cultural appreciation.",
  },
  {
    imageUrl: "url_to_image3",
    title: "Character Development",
    description:
      "We prioritize character development through values-based education, promoting integrity, empathy, and responsibility.",
  },
  {
    imageUrl: "url_to_image4",
    title: "Inclusive Learning Environment",
    description:
      "Our school provides a supportive and inclusive learning environment where every student feels valued, respected, and included.",
  },
  {
    imageUrl: "url_to_image5",
    title: "Global Citizenship",
    description:
      "We empower students to become global citizens by fostering cultural awareness, global perspectives, and social responsibility.",
  },
  {
    imageUrl: "url_to_image6",
    title: "Project-Based Learning",
    description:
      "We implement project-based learning approaches to engage students in real-world challenges and encourage collaboration and critical thinking.",
  },
  {
    imageUrl: "url_to_image7",
    title: "Environmental Sustainability",
    description:
      "Our school is committed to environmental sustainability through eco-friendly practices and environmental education initiatives.",
  },
  {
    imageUrl: "url_to_image8",
    title: "Physical Fitness and Wellness",
    description:
      "We promote physical fitness and wellness through comprehensive physical education programs and health education initiatives.",
  },
  {
    imageUrl: "url_to_image9",
    title: "Technology Integration",
    description:
      "We leverage technology integration to enhance learning experiences, digital literacy, and 21st-century skills development.",
  },
  {
    imageUrl: "url_to_image10",
    title: "Community Engagement",
    description:
      "We actively engage with the community through partnerships, service-learning projects, and outreach initiatives to enrich student experiences.",
  },
];

const FOOTER_MENU1 = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "About Us",
    url: "/about",
  },
  {
    name: "Terms & Conditions",
    url: "/terms-conditions",
  },
  {
    name: "Contact Us",
    url: "/contact",
  },
];

const FOOTER_MENU2 = [
  {
    name: "Facebook",
    url: "#",
  },
  {
    name: "Instagram",
    url: "#",
  },
  {
    name: "You Tube",
    url: "#",
  },
  {
    name: "+91 9999 99999",
    url: "https://wa.me/(91{MOBILE-NUMBER})/",
    // e.g. : https://wa.me/919876543210/
    _blank: true,
  },
  {
    name: "abc@123gmail.com",
    url: "https://mail.google.com/mail/u/0/?fs=1&to={EMAIL-ID}&tf=cm",
    // e.g. : "https://mail.google.com/mail/u/0/?fs=1&to=abc@123gmail.com&tf=cm",
  },
];

const STATUS = {
  FAILED: "FAILED",
  OK: "OK",
  PENDING: "pending",
  COMPLETED: "completed",
};

const ENV = process.env.REACT_APP_ENV;
export {
  STATICS,
  SCHOOL,
  NAVBAR_ITEMS,
  academics,
  ACADEMICS_ITEMS,
  STANDARD_ITEMS,
  EXPERTIES,
  FOOTER_MENU1,
  FOOTER_MENU2,
  ENV,
  STATUS,
};
