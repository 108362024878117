import axios from "axios";

let baseURL = "";
if (process.env.REACT_APP_ENV_PRODUCTION) {
  baseURL = `${process.env.REACT_APP_ADMIN_PANEL_BACKEND_DOMAIN_PRODUCTION}/api/v1`;
} else if (process.env.REACT_APP_ENV_LOCAL) {
  baseURL = `${process.env.REACT_APP_ADMIN_PANEL_BACKEND_DOMAIN_LOCAL}/api/v1`;
}
const client = axios.create({ baseURL });

// Add an interceptor to handle 401 (Unauthorized) response
client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Redirect to the unauthorized page
      const allowedPaths = ["/unauthorized"];

      const currentPath = window.location.pathname;

      if (!allowedPaths.some((path) => currentPath.startsWith(path))) {
        window.location.href = "/unauthorized"; // Replace with the actual path of the unauthorized page
      }
    }
    return Promise.reject(error);
  }
);

// Add an interceptor to include the authentication token in the request headers
// client.interceptors.request.use(async (config) => {
//   // Dynamically get the latest authToken before each request
//   const authToken = await getToken();
//   if (authToken) {
//     config.headers["authorization"] = `Bearer ${authToken}`;
//   }
//   return config;
// });

export default client;
