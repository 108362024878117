import React from "react";
import { Link } from "react-router-dom";

const ButtonLink = ({ url = "#", label = "" }) => {
  return (
    <Link to={url} className="">
      <button
        type="button"
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-sm text-sm px-5 py-2.5 text-center"
      >
        {label}
      </button>
    </Link>
  );
};

export default ButtonLink;
