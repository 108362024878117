import React from "react";
import Container from "../../layouts/Container";
import Heading from "../../layouts/Heading";
import { SCHOOL } from "../../utils/constants";
import { Divider } from "antd";
import { about_us_content } from "../../utils/static";

const AboutDescriptionSection = () => {
  const aboutusLines = about_us_content.split("##");
  return (
    <section className="my-10">
      <Container>
        <div className="my-2">
          <Heading className="text-blue-600 capitalize">About Us</Heading>
        </div>
        <Divider />
        <div className="mb-4 lg:py-4">
          <div className="rounded-lg lg:shadow lg:bg-[#F9FAFB] lg:p-10 sm:p-5 p-3 text-justify">
            <div className="mb-3 text-black text-justify">
              <span></span>
              Welcome to{" "}
              <span className="text-blue-600 font-semibold">
                {SCHOOL.FULLNAME}{" "}
              </span>
              <br />
              <br />
              <div className="sm:pl-10">
                {aboutusLines.map((line, index) => {
                  return (
                    <li
                      key={index}
                      className="mb-1 text-cyan-800 font-medium capitalize"
                    >
                      {line}
                    </li>
                  );
                })}
              </div>
            </div>
            ~ The{" "}
            <span className="text-blue-600 font-semibold">
              {SCHOOL.FULLNAME}
            </span>{" "}
            Team
          </div>
        </div>
      </Container>
    </section>
  );
};

export default AboutDescriptionSection;
