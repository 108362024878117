import React from "react";

const Video = ({ src = "", autoPlay = true, type = "video/mp4" }) => {
  return (
    <video controls autoPlay={autoPlay} className="rounded-lg">
      <source src={src} type={type} />
      Your browser does not support the video tag.
    </video>
  );
};

export default Video;
