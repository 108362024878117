import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ title, content = "" }) => {
  return (
    <Helmet>
      <title>{title ? title : "VVG"} - Vidhyashan Vikas Group</title>
      <meta
        name="description"
        content={`Vidhyashan Vikas Group - ${content}`}
      />
    </Helmet>
  );
};

export default SEO;
