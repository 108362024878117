import React from "react";

const Heading = ({ className, children }) => {
  const classes = className;
  return (
    <div className={classes + " font-bold sm:text-3xl text-2xl"}>
      {children}
    </div>
  );
};

export default Heading;
