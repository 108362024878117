import React from "react";
import { SCHOOL } from "../../utils/constants";
import { InstagramFilled } from "@ant-design/icons";
import Facebook from "../../assets/icons/Facebook";
import Youtube from "../../assets/icons/Youtube";
import { Link } from "react-router-dom";
import Envelop from "../../assets/icons/Envelop";
import Mobile from "../../assets/icons/Mobile";

const ContactPanel = () => {
  return (
    <div className="flex justify-around items-center p-1.5 bg-blue-600">
      <div className="md:flex gap-2 flex">
        <Link to={SCHOOL.SOCIAL_MEDIA.FACEBOOK} target="_blank">
          <Facebook style={{ color: "white" }} />
        </Link>
        <Link to={SCHOOL.SOCIAL_MEDIA.INSTAGRAM} target="_blank">
          <InstagramFilled className="text-white w-6 h-6" />
        </Link>
        <Link to={SCHOOL.SOCIAL_MEDIA.YOUTUBE} target="_blank">
          <Youtube style={{ color: "white" }} />
        </Link>
      </div>
      <marquee
        className="flex gap-2 text-white w-fit lg:text-base text-sm"
        scrollamount="8"
        direction="left"
      >
        No.1 In Panchmahals | Best Concept School (Only Gujarati Medium) | Days
        Monday to Saturday Timing : 11 A.M. to 5 P.M.
      </marquee>
      <div className="md:flex text-white flex items-center">
        <Link
          to={`mailto:${SCHOOL.EMAIL}`}
          className="flex gap-1 italic underline"
        >
          <Envelop /> <span className="xl:block hidden">{SCHOOL.EMAIL}</span>
        </Link>
        <span className="xl:block hidden">&nbsp; | &nbsp;</span>
        <Link className="flex gap-1 font-semibold underline" to={"https://wa.me/919408101842"}>
          {" "}
          <Mobile />
          <span className="xl:block hidden">{SCHOOL.MOBILE}</span>
        </Link>
      </div>
    </div>
  );
};

export default ContactPanel;
