import React from "react";
import { Outlet } from "react-router";
import Navbar from "../../components/Navbar";
import {
  ACADEMICS_ITEMS,
  NAVBAR_ITEMS,
  SCHOOL,
  STANDARD_ITEMS,
  academics,
} from "../../utils/constants";
import StandardsApis from "../../api/standards";
import { useLoaderData } from "react-router-dom";
import Footer from "../../components/Footer";
import ContactPanel from "../../components/ContactPanel";

const MainLayout = () => {
  const { standards, error } = useLoaderData();
  const STD_ITEMS = error ? [] : STANDARD_ITEMS(standards);

  return (
    <div>
      <div className="p-0 m-0 mb-5">
        <ContactPanel />
        <Navbar
          items={NAVBAR_ITEMS}
          logo={SCHOOL.SHORTFORM}
          standards={standards}
          academics={academics}
        />
      </div>
      <div className="min-h-screen">
        <Outlet />
      </div>
      <div className="p-0 m-0 mb-5">
        <Footer />
      </div>
    </div>
  );
};
export default MainLayout;

export async function loadMainLayoutData() {
  try {
    const { data } = await StandardsApis.getAllStandards();
    return { standards: data.data };
  } catch (error) {
    return {
      error: "Internal Server Error",
    };
  }
  return null;
}
