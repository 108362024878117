import Container from "../../layouts/Container";
import SEO from "../../components/SEO";
import { Divider } from "antd";
import Heading from "../../layouts/Heading";
import YTVideo from "../../components/YTVideo";
import { YT_VIDEO_IDS } from "../../utils/static";


export default function VideosPage() {

  return (
    <Container>
      <SEO title="Videos" />
      <Divider className="border-0" />
      <Heading className="text-blue-600 capitalize">Videos</Heading>
      <Divider />

      <div className="w-full grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 justify-center gap-2">
        {YT_VIDEO_IDS.map((video_id)=>{
          return <YTVideo YT_ID={video_id} key={video_id} />;
        })}
      </div>
    </Container>
  );
}

