import React, { useEffect, useState } from "react";
import Container from "../layouts/Container";
import Heading from "../layouts/Heading";
import PortfolioCard from "./PortfolioCard";
import ButtonLink from "./ButtonLink";
import ResultApis from "../api/results";
import { Divider, Empty, Spin } from "antd";

const ResultSection = ({
  years = [],
  isBg = true,
  seeMoreBtn = true,
  heading = "our latest results",
  passingYearLabel = true,
}) => {
  const [results, setResults] = useState([]);
  const [load, setLoad] = useState(false);

  async function fetchData() {
    const results = [];
    setLoad(true);

    for (const year of years) {
      try {
        const { data } = await ResultApis.getAllResultsByYear(year);
        results[year] = data?.data;
      } catch (error) {
        results[year] = undefined;
      }
    }

    setResults(results);
    setLoad(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <section className="my-10">
      <Heading className="text-blue-600 capitalize">{heading}</Heading>
      <Divider />
      {results.length === 0 && load ? (
        <div className="min-h-52">
          <Spin className="w-full min-h-20 flex items-center justify-center" />
        </div>
      ) : (
        <div className={`my-4 py-5 ${isBg ? "bg-blue-gradiant" : ""} py-4`}>
          <Container>
            {results?.map((results, year) => {
              return (
                <div key={year}>
                  <Heading className="text-blue-800 text-center sm:text-xl text-base">
                    {year} Passout batch
                  </Heading>
                  <Divider />

                  {typeof results === "undefined" ? (
                    <Empty
                      className="md:hidden"
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  ) : (
                    <div className="grid gap-5 grid-flow-row grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 my-3">
                      {results.map((result) => {
                        return (
                          <PortfolioCard
                            key={result?._id}
                            className="mx-auto"
                            name={result?.studentName}
                            pr={result?.percentile}
                            passingYear={
                              passingYearLabel ? result?.passingYear : undefined
                            }
                            imageUrl={result?.studentImage}
                          />
                        );
                      })}
                    </div>
                  )}
                  <Divider className="border-0" />
                  <Divider className="border-0" />
                </div>
              );
            })}

            {seeMoreBtn && (
              <div className="flex justify-center items-center mt-10">
                <ButtonLink url="/results" label="See More ..." />
              </div>
            )}
          </Container>
        </div>
      )}
    </section>
  );
};

export default ResultSection;
