import React from "react";
import Container from "../../layouts/Container";
import SEO from "../../components/SEO";
import Heading from "../../layouts/Heading";
import { Divider } from "antd";
import { results } from "../../utils/pdfs";
import PdfComp from "../../components/PDF";

const ResultsPage = () => {
  return (
    <Container>
      <SEO title="Results" />
      <Divider className="border-0" />
      <div className="my-2">
        <Heading className="text-blue-600 capitalize">Results</Heading>
      </div>
      <Divider />
      {
        results.map((resultPDF, index)=>{
          return <ResultPDF resultPDF={resultPDF} key={index} title=""/>
        })
      }
    </Container>
  );
};

export default ResultsPage;


function ResultPDF({title = "", resultPDF}) {
  return(
    <>
      <div className="flex justify-center items-center">
        <PdfComp pdfFile={resultPDF} />
      </div>
      <Divider className="border-0"/>
    </>
  );
}