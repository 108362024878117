import React from "react";

const YTVideo = ({ YT_ID = "", className="" }) => {
  return (
    <iframe
      className={`video w-full rounded-lg ${className}`}
      title="Youtube player"
      sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
      src={`https://youtube.com/embed/${YT_ID}?autoplay=0`}
      allowFullScreen={true}
    />
  );
};

export default YTVideo;
